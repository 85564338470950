<section class="container mb-5 mb-md-0" >
  <div class="sfs-cta-container-bg shadow-sm d-flex align-items-center p-5" [class]="class">
    <div class="row">
      <div class="col-12">
        <h2 class="h1 text-white"><ng-container i18n>Having questions?</ng-container><br> <ng-container i18n>Check out our knowledgebase!</ng-container></h2>
        <a class="btn btn-secondary mb-3 me-2" href="https://wman.com/faq" target="_blank" i18n>Check knowledgebase</a>
      </div>
      
    </div>
  </div>
  
</section>
  
  
  