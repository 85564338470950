
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile } from '../../../../../../shared/models';
import { LoginResponse } from '../../../../../../shared/models/login-response';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ReviewService {
  routePath = 'review';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  getReviews(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getReviews/`);
    return this.httpService.post<any>(url,body);
  }

  getAllReviews(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getAllReviews/`);
    return this.httpService.post<any>(url,body);
  }

  getMyReviews(): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getMyReviews/`);
    return this.httpService.post<any>(url,{});
  }

  getReviewsV2(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getReviewsV2/`);
    return this.httpService.post<any>(url,body);
  }

  removeReview(body): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/removeReview/`);
    return this.httpService.post<any>(url,body);
  }

  getReviewsStats(body): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getReviewsStats/`);
    return this.httpService.post<any>(url,body);
  }

  
  
  

}
