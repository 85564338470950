import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CreateSightComponent } from 'src/app/components/tour-platform/components/create-sight/create-sight.component';
import { CreateTourComponent } from 'src/app/components/tour-platform/components/create-tour/create-tour.component';

@Injectable({
  providedIn: 'root'
})
export class TourFormService {
tourEdited: any;
hideModalSubscription: any;
tourModalSubscription: any;
constructor(private modalService: BsModalService, private location: Location, private router: Router, private route: ActivatedRoute, private activatedRoute: ActivatedRoute) { }

dropSightModal(sight?: any, mode?: string){
  setTimeout(() => {  
    this.modalService.show(CreateSightComponent, { class: 'modal-xxl modal-dialog-centered sfs-tour-platform-modal sfs-tour-platform-modal', ignoreBackdropClick: false, initialState: {sight, type: mode ?? 'create'} }); 
    
      this.hideModalSubscription = this.modalService.onHide.subscribe((x) => {

         // Remove query string from URL
        let currentRouteUrl = this.router.url.split('?')[0];
        let queryParams = new URLSearchParams(this.activatedRoute.snapshot.queryParams);
        queryParams.delete('sId');
        let queryString = queryParams.toString();
        this.location.replaceState(queryString ? `${currentRouteUrl}?${queryString}` : currentRouteUrl);

        this.hideModalSubscription.unsubscribe();
      }); 
   
  },500);
}

dropTourFormModal(tour?: any, mode?: string){
  this.tourModalSubscription = this.modalService.show(CreateTourComponent, { class: 'modal-xxl modal-dialog-centered sfs-tourPlatform-modal sfs-tour-platform-modal', ignoreBackdropClick: false, initialState: {tour, mode }}); 
  this.tourModalSubscription = this.modalService.onHide.subscribe((x) => {
   
    // Remove query string from URL
    let currentRouteUrl = this.router.url.split('?')[0];
    let queryParams = new URLSearchParams(this.activatedRoute.snapshot.queryParams);
    queryParams.delete('tId');
    let queryString = queryParams.toString();
    this.location.replaceState(queryString ? `${currentRouteUrl}?${queryString}` : currentRouteUrl);

    this.tourModalSubscription.unsubscribe();
    
  }); 
}

}
