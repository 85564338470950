import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SightListComponent } from './sight-list.component';
import { SightsMapModule } from '../sights-map/sights-map.module';

import { NoDataBlockModule } from '../no-data-block/no-data-block.module';
import { LoaderModule } from '../loader/loader.module';



@NgModule({
  declarations: [
    SightListComponent
  ],
  imports: [
    CommonModule,
    SightsMapModule,
    LoaderModule,
    NoDataBlockModule
  ], exports: [SightListComponent]
})
export class SightListModule { }
