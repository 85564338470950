import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingCitiesComponent } from './landing-cities.component';
import {NgxTinySliderModule} from 'ngx-tiny-slider';



@NgModule({
  declarations: [
    LandingCitiesComponent
  ],
  imports: [
    CommonModule,
    NgxTinySliderModule
  ], 
  exports: [LandingCitiesComponent]
})
export class LandingCitiesModule { }
