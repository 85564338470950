import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component'


@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [],
  exports: [PageNotFoundComponent]
})
export class CommonPagesModule {}
