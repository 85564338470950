import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SightsMapComponent } from './sights-map.component';
import e from 'express';



@NgModule({
  declarations: [
    SightsMapComponent
  ],
  imports: [
    CommonModule
  ], exports: [SightsMapComponent]
})
export class SightsMapModule { }
