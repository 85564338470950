import { Injectable } from '@angular/core';
import Noty from 'noty';


@Injectable()
export class NotyService {
  constructor() {}

  dropNoty(type, text, timeout?){
      if(!timeout){
          timeout = 4000;
      }
    new Noty({
        type,
        text,
        killer: true,
        timeout
    }).show();
  }


  
  
}
