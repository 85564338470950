import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourMapComponent } from './tour-map.component';



@NgModule({
  declarations: [TourMapComponent],
  imports: [
    CommonModule
  ], exports: [TourMapComponent]
})
export class TourMapModule { }
