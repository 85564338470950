import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfile } from '../../../../../../shared/models';
import { LoginResponse } from '../../../../../../shared/models/login-response';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UserService {
  routePath = 'users';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  updateUser(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/updateUser/`);
    return this.httpService.post<any>(url,{body});
  }

  changePassword(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/changePassword/`);
    return this.httpService.post<any>(url,{body});
  }

  forgotPassword(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/forgotPassword/`);
    return this.httpService.post<any>(url,body);
  }

  getTourPlatformUsers(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTourPlatformUsers/`);
    return this.httpService.post<any>(url,body);
  }

  addNewUser(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/addNewUser/`);
    return this.httpService.post<any>(url,body);
  }

  toggleActivateUser(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/toggleActivateUser/`);
    return this.httpService.post<any>(url,body);
  }


  deleteUser(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/deleteUser/`);
    return this.httpService.post<any>(url,body);
  }

  

}
