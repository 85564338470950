import { ToastrService } from 'ngx-toastr';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserProfileModel } from '../../../../../shared/models/user-profile.model';
import { ApiService, AuthService, NotyService } from '@services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { LocaleService } from '@core/services/misc/locale.service';
import { ConfigService } from '@core/services/config.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit{
  userProfileForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl(''),
  });
  pageSettings = {
    loaders: {
      register: false
    }
  }
  optIn: boolean = false;

  constructor(
    private apiService: ApiService,
    public configService: ConfigService,
    private router: Router,
    private authService: AuthService,
    private notyService: NotyService,
    private localeService: LocaleService
  ) {}

  async checkLoggedInStates(){
    const isLoggedIn = await this.authService.isLoggedInAsync
    if(isLoggedIn){
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(){
   this.checkLoggedInStates();
  }

  /**
   * Performs the login action
   */
  login(){
    this.authService.login(this.userProfileForm.controls['email'].value, this.userProfileForm.controls['password'].value).pipe(take(1)).subscribe((x)=>{
        this.router.navigateByUrl('/tours');
    });
  }

  /**
   * Performs the registration action
   */
  onRegisterClick(): void {
    this.pageSettings.loaders.register = true;
    const urlParams = new URLSearchParams(window.location.search);
    const roleParam = urlParams.get('type');
    this.apiService.register(_.merge(this.userProfileForm.getRawValue(), roleParam === 'creator' ? {
      roles: ['creator']
    } : {
      locale: this.localeService.activeLocaleObject ? this.localeService.activeLocaleObject.shortHand : 'en'
    })).subscribe((x: any) => {
      if(x && x.success){
        this.login();
      } else{
        this.notyService.dropNoty('error',$localize `The email address is already in use.`)
      }
      this.pageSettings.loaders.register = false;
    }, (err: any)=>{
      this.pageSettings.loaders.register = false;

    });
  }
}
