import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourModalComponent } from './tour-modal.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NoDataBlockModule } from '../../modules/no-data-block/no-data-block.module';
import {AudioPreviewModule} from '../../modules/audio-preview/audio-preview.module';
import { SightListModule } from '../sight-list/sight-list.module';
import { TourPreviewModule } from '../tour-preview/tour-preview.module';

@NgModule({
  declarations: [
    TourModalComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    NgxDnDModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NoDataBlockModule,
    NgxDnDModule,
    SightListModule,
    TourPreviewModule,
    AudioPreviewModule,
  ],
  exports: [TourModalComponent]
})
export class TourModalModule { }
