<!-- List mode -->
<div *ngIf="pageSettings.isList else mapBlock">
    <section class="container p-md-5 ">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 pb-2 p-3 p-md-0">
            <h2 class="h3 mb-sm-0" i18n>Tours</h2>
            <div class="d-flex">
                <ng-container i18n>Selected city:</ng-container> <span class="dropdown d-inline-block me-3">
                    <a class="dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">&nbsp;{{detectCity() ?
                        pageSettings.selectedCity.name : locales.All}}</a>
                    <span class="dropdown-menu dropdown-menu-end" *ngIf="configService.cities">
                        <a class="dropdown-item fs-base fw-bold" href="javascript:void(0)" (click)="filter(city)"
                            *ngFor="let city of configService.cities">{{city.name}}</a></span></span>

                <span class="dropdown d-inline-block d-ms-auto" *ngIf="languages.length > 0">
                    <a class="dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                            class="flag-icon flag-icon-{{selectedLanguage.code === 'en' ? 'gb' : selectedLanguage.code}} me-1"></span>{{selectedLanguage.name}}</a>
                    <span class="dropdown-menu dropdown-menu-end my-1">
                        <a class="dropdown-item fs-base fw-bold" href="javascript:void(0)"
                            (click)="filterLanguage(language)" *ngFor="let language of languages"><span
                                class="flag-icon flag-icon-{{language.code === 'en' ? 'gb' : language.code}} me-1"></span>{{language.name}}</a></span></span>


            </div>
        </div>
        <div>
            <app-landing-categories [isFilter]="true" [isFactoryFilter]="true" [selectedCategories]="selectedCategories"
                (categoryChanged)="categoryChanged($event)"></app-landing-categories>

        </div>
        <!-- Tours -->
        <div class="row mt-5" *ngIf="tours && tours.length else noDataBlock">
            <div class="col-12">
                <app-tours-card-list [tours]="tours"></app-tours-card-list>
                <!-- Map switch -->
                <ng-container *ngTemplateOutlet="mapSwitch"></ng-container>
            </div>
        </div>
        <!-- End of Tours -->
        <!-- No data block -->
        <ng-template #noDataBlock>
            <app-no-data-block [callback]="redirectToMediaUpload" [title]="locales.NoTours"
                [subtitle]="locales.TryToModify"></app-no-data-block>
        </ng-template>

    </section>

</div>
<!-- End of List mode -->
<!-- Map mode -->
<ng-template #mapBlock>
    <div id="tourMap">
        <ng-container *ngTemplateOutlet="mapSwitch"></ng-container>
    </div>
</ng-template>
<!-- End of Map mode -->
<ng-template #mapSwitch>
    <div class="sfs-map-switch-container" *ngIf="tours && tours.length">
        <button class="btn btn-primary btn-lg rounded-pill" (click)="toggleMap()">
            <span *ngIf="pageSettings.isList else listBtn"><i class="fi-map me-2"></i>
                <ng-container i18n>Map view</ng-container>
            </span>
            <ng-template #listBtn><i class="fi-list me-2"></i>
                <ng-container i18n>List view</ng-container>
            </ng-template>
        </button>
    </div>
</ng-template>
