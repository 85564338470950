import { Component,Input } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-no-data-block',
  templateUrl: './no-data-block.component.html',
  styleUrls: ['./no-data-block.component.scss']
})
export class NoDataBlockComponent{
  @Input() title = $localize `No data`;
  @Input() icon = "fi-map-pins";
  @Input() subtitle =  $localize `No data`;
  @Input() navigateTo;

  /**
   * Navigates to url
   * @param url - url to navigate to
   */
  navigate(url){
    this.router.navigate([url]);
  }

  constructor(private router: Router) { }


}
