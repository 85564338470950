import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@core/services/config.service';


@Component({
  selector: 'app-landing-categories',
  templateUrl: './landing-categories.component.html',
  styleUrls: ['./landing-categories.component.scss']
})
export class LandingCategoriesComponent implements OnInit {
  @Input() isFilter = false;
  @Input() isFactoryFilter = false;
  @Input() selectedCategories = [];
  @Output() categoryChanged = new EventEmitter();
  isSelected = false;
  isFactoryMade = false;
  constructor(public configService: ConfigService, private router: Router) { }

  
  /**
   * Activates the selected category
   * @param category The category object to activate
   */
  selectCategory(category){
    if(this.isFilter){
      this.isSelected = !this.isSelected;
      this.categoryChanged.emit({categoryId: category.code, isFactoryMade: this.isFactoryMade});
    } else{
      this.router.navigate(['/tours'],{queryParams: {categoryId: category.code}})
    }
  }

  /**
   * Performs change event when a new category is selected
   */
  emitChange(){
    this.categoryChanged.emit({isFactoryMade: this.isFactoryMade});
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
  }

}
