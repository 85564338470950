import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ErrorService {
  routerPath = "errors";

  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  createError(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routerPath}/create`);
    return this.httpService.post<any>(url, body);
  }

  deleteError(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routerPath}/deleteError`);
    return this.httpService.post<any>(url, body);
  }

  getErrorsByTourId(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routerPath}/getErrorsByTourId`);
    return this.httpService.post<any>(url, body);
  }

  acceptError(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routerPath}/acceptError`);
    return this.httpService.post<any>(url, body);
  }

  getErrorsBySight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routerPath}/getErrorsBySight`);
    return this.httpService.post<any>(url, body);
  }
}
