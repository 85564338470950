import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SightModalComponent } from './sight-modal.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SightMapModule } from '../../modules/sight-map/sight-map.module';
import { SightPreviewModule } from '../../modules/sight-preview/sight-preview.module';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NoDataBlockModule } from '../../modules/no-data-block/no-data-block.module';
import {AudioPreviewModule} from '../../modules/audio-preview/audio-preview.module';


@NgModule({
  declarations: [
    SightModalComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    SightMapModule,
    SightPreviewModule,
    NgxDnDModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NoDataBlockModule,
    NgxDnDModule,
    AudioPreviewModule,
  ], exports: [SightModalComponent]
})
export class SightModalModule { }
