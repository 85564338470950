import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchListComponent } from './search-list.component';
import {NgxTinySliderModule} from 'ngx-tiny-slider';
import { RouterModule } from '@angular/router';
import { TourCardModule } from '../tour-card/tour-card.module';




@NgModule({
  declarations: [
    SearchListComponent
  ],
  imports: [
    CommonModule,
    NgxTinySliderModule,
    RouterModule,
    TourCardModule
  ], exports: [SearchListComponent]
})
export class SearchListModule { }
