<div class="modal-header no-border">
    <h4 class="modal-title pull-left i18n" i18n>Buy credits</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-3" *ngIf="configService.packages && configService.packages">
        <div class="col-12 col-sm-6 col-xl-3 mb-4">
            <div class="card shadow-sm">
                <div class="card-body pb-0"><img class="d-block mx-auto mt-2 mb-4" src="../../../../assets/img/pricing/icon3.svg" width="72"
                        alt="Icon">
                    <h2 class="h5 fw-normal text-center py-1 mb-0">{{configService.packages.basic.name}}</h2>
                    <div class="d-flex align-items-end justify-content-center mb-4">
                        <div class="text-center">
                            <div class="h2 mb-0">{{configService.packages.basic.credit}}&nbsp;<ng-container i18n>credits</ng-container> </div>
                        <div><small class="text-muted"><ng-container i18n>Equals to</ng-container> ${{configService.packages.basic.price}}</small></div>
                        </div>
                        
                    </div>

                </div>
                <div class="card-footer py-2 border-0">
                    <div class="border-top text-center pt-4 pb-3"><button class="btn btn-outline-primary" (click)="selectPackage(configService.packages.basic.stripeId)" [disabled]="pageSettings.navigate"><ng-container i18n>Buy</ng-container> {{configService.packages.basic.credit}} <ng-container i18n>credits</ng-container></button></div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-3 mb-4">
            <div class="card shadow">
                <div class="card-body pb-0"><img class="d-block mx-auto mt-2 mb-4" src="../../../../assets/img/pricing/icon2.svg" width="72"
                        alt="Icon">
                    <h2 class="h5 fw-normal text-center py-1 mb-0">{{configService.packages.couple.name}}</h2>
                    <div class="d-flex align-items-end justify-content-center mb-4">
                        <div class="text-center">
                            <div class="h2 mb-0">{{configService.packages.couple.credit}}&nbsp;<ng-container i18n>credits</ng-container></div>
                        <div><small class="text-muted"><ng-container i18n>Equals to</ng-container> ${{configService.packages.couple.price}}</small></div>
                        </div>
                        
                    </div>
                </div>
                <div class="card-footer py-2 border-0">
                    <div class="border-top text-center pt-4 pb-3"><button class="btn btn-primary" (click)="selectPackage(configService.packages.couple.stripeId)" [disabled]="pageSettings.navigate"><ng-container i18n>Buy</ng-container> {{configService.packages.couple.credit}} <ng-container i18n>credits</ng-container></button></div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-3 mb-4">
            <div class="card shadow-sm">
                <div class="card-body pb-0"><img class="d-block mx-auto mt-2 mb-4" src="../../../../assets/img/pricing/icon1.svg" width="72"
                        alt="Icon">
                    <h2 class="h5 fw-normal text-center py-1 mb-0">{{configService.packages.family.name}}</h2>
                    <div class="d-flex align-items-end justify-content-center mb-4">
                        <div class="text-center">
                            <div class="h2 mb-0">{{configService.packages.family.credit}}&nbsp;<ng-container i18n>credits</ng-container></div>
                        <div><small class="text-muted"><ng-container i18n>Equals to</ng-container> ${{configService.packages.family.price}}</small></div>
                        </div>
                        
                    </div>
                </div>
                <div class="card-footer py-2 border-0">
                    <div class="border-top text-center pt-4 pb-3"><button class="btn btn-outline-primary"  (click)="selectPackage(configService.packages.family.stripeId)" [disabled]="pageSettings.navigate"><ng-container i18n>Buy</ng-container> {{configService.packages.family.credit}} <ng-container i18n>credits</ng-container></button></div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-3 mb-4">
            <div class="card shadow-sm">
                <div class="card-body pb-0"><img class="d-block mx-auto mt-2 mb-4" src="../../../../assets/img/pricing/icon4.png" width="72"
                        alt="Icon">
                    <h2 class="h5 fw-normal text-center py-1 mb-0">{{configService.packages['family-extra'].name}}</h2>
                    <div class="d-flex align-items-end justify-content-center mb-4">
                        <div class="text-center">
                            <div class="h2 mb-0">{{configService.packages['family-extra'].credit}}&nbsp;<ng-container i18n>credits</ng-container></div>
                        <div><small class="text-muted"><ng-container i18n>Equals to</ng-container> ${{configService.packages['family-extra'].price}}</small></div>
                        </div>
                        
                    </div>
                </div>
                <div class="card-footer py-2 border-0">
                    <div class="border-top text-center pt-4 pb-3"><button class="btn btn-outline-primary"  (click)="selectPackage(configService.packages['family-extra'].stripeId)" [disabled]="pageSettings.navigate"><ng-container i18n>Buy</ng-container> {{configService.packages['family-extra'].credit}} <ng-container i18n>credits</ng-container></button></div>
                </div>
            </div>
        </div>
    </div> 
    <div class="row py-2 px-5">
        <div class="col-12 text-center">
            <img src="../../../../assets/img/payment/stripe.png" width="400" alt="">
        </div>
    </div>
</div>


