import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimilarToursComponent } from './similar-tours.component';
import { TourCardModule } from '../tour-card/tour-card.module';
import { NoDataBlockModule } from '../no-data-block/no-data-block.module';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    SimilarToursComponent
  ],
  imports: [
    CommonModule,
    NoDataBlockModule,
    TourCardModule,
    HttpClientModule
  ], exports: [SimilarToursComponent]
})
export class SimilarToursModule { }
