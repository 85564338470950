import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FavoritesService, TourService, UserStateService } from '@services';
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"
import * as _ from "lodash";
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit, AfterViewInit, OnDestroy {
  user;
  authSubscription;
  
  @Input() type = 'recent';
  @Input() class;
  @Input() classExt;
  @Input() title;
  tours = [];



  constructor(private tourService: TourService, private userStateService: UserStateService, private favoritesService: FavoritesService) { }

  /**
   * Checks for user authentication changes
   */
  initUserChangeDetection(){
    this.authSubscription = this.userStateService.user.subscribe((user)=>{
      if(user){
       this.user = user;
      } else{
        this.user = null;
      }
     })
  }

  /**
   * Gets the recent tours from the server
   */
  getRecentTours(){
    this.tourService.getRecent().pipe(take(1)).subscribe((x)=>{ 
      this.tours = x;
      
      this.drawCarousel();
    })
  }

  /**
   * Gets the nearby tours from the server
   */
  getNearby(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any)=>{
        
        this.tourService.getNearby({pos: [position.coords.latitude, position.coords.longitude]}).pipe(take(1)).subscribe((x)=>{ 
          this.tours = _.map(x, (y)=>{return _.merge(y, {distance: y.distance > 0.1 ? (y.distance).toFixed(1) : 0.1 })});
          this.drawCarousel();
        })
      });
    } 
  }

  refreshEvent($event){
    
  }

  /**
   * Decides whether to add to favorites or remove from favorites
   * @param id id of the tour to be added to favorites
   * @returns 
   */
  isLiked(id){
    if(this.user){
      return this.favoritesService.isLiked(this.user, id);
    }
  }

  /**
   * Draws the tour carousel
   */
  drawCarousel(){
    setTimeout(()=>{
      if(document.querySelector(this.classExt)){
        let slider = tns({
          container: this.classExt,
          slideBy: 'page',
          gutter: 20,
          autoplay: false,
          autoplayButtonOutput: false,
          controls: false,
          responsive: {
            576: {
              items: 1
            },
            768: {
              items: 2
            },
            992: {
              items: 3
            }
          },
        });
      }
    },10)
  }

  /**
   * Default component after view  initialization
   */
  ngAfterViewInit() {
    if(this.type === 'recent'){
      this.getRecentTours();
    } else if(this.type === 'nearby'){
      this.getNearby();
    }
    
  }
  /**
   * Default component destruction
   */
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
    this.initUserChangeDetection();
  }

}
