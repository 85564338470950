import { AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import mapboxgl from 'mapbox-gl'
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-tour-sightseeing',
  templateUrl: './tour-sightseeing.component.html',
  styleUrls: ['./tour-sightseeing.component.scss']
})
export class TourSightseeingComponent implements OnInit, AfterViewInit {
  @Input() tour;
  map;
  mapId;
  locations = [];
  markers = [];
  activeIndex = 0;
  pageSettings = {
    isAutoplay: true
  };
  constructor() {
   this.mapId = (Math.random() + 1).toString(36).substring(7);
  }

  /**
   * Initialize the 3D map
   */
  initMap() {
    mapboxgl.accessToken = environment.mapBoxAccessToken;
    if(!this.map){
      this.map = new mapboxgl.Map({
        container: this.mapId,
        style: 'mapbox://styles/mapbox/satellite-v9',
        zoom: 18,
        interactive: false,
        center: this.tour.sights[0].coordinates.coordinates
      });
      this.drawMarkers();
      setTimeout(() => {
        this.playBack(0);
      }, 5000)
    } else{
      this.map.resize();
    }
  }
  
  /**
   * Jump to the next sight
   * @param index sight index
   */
  playBack(index) {
    // Animate the map position based on camera properties.
    this.map.flyTo(this.locations[index].camera);
    this.activeIndex = index;
    this.map.once('moveend', () => {
      // Duration the slide is on screen after interaction.
      if (this.pageSettings.isAutoplay) {
        window.setTimeout(() => {

          index = (index + 1) % this.locations.length;
          this.playBack(index);

          // Increment index, looping back to the first after the last location.

        }, 3000); // After callback, show the location for 3 seconds.
      } 
    });
  }

  /**
   * Fit the map to the bounds of the markers
   */
  fitMapToBounds() {
    let bounds = new mapboxgl.LngLatBounds();
    this.markers.forEach(function (feature) {
      bounds.extend([feature.getLngLat().lng, feature.getLngLat().lat]);
    });
    this.map.fitBounds(bounds, { padding: 50, duration: 1000 });
  }

  /**
   * Draw the markers on the map
   */
  drawMarkers() {
    _.forEach(this.tour.sights, (x, i) => {
      const el = document.createElement('div');
      el.innerHTML = '<div class="sfs-marker sfs-text-black text-white">' + (i + 1) + '.</div>' + x.tourName.value;
      el.className = 'sfs-marker-container text-white';
      let marker = new mapboxgl.Marker(el, { draggable: false }).setLngLat(x.coordinates.coordinates).addTo(this.map);
      this.markers.push(marker);
    })
    if (this.tour.sights.length > 0) {
      this.fitMapToBounds();
    }
  }

  /**
   * Generate the sight locations for the map
   */
  generateLocations() {
    let bearingStart = 0;
    this.tour.sights = _.map(this.tour.sights, (sight) => { return _.merge(sight, { tourDescription: (sight.tourDescription.value.length < 150 ? sight.tourDescription.value : sight.tourDescription.value.substr(0, 150) + '...') }) })
    this.locations = _.map(this.tour.sights, (sight, index) => {
      
      bearingStart += 50;
      if (bearingStart > 350) {
        bearingStart = 0;
      }
      return {
        id: index,
        camera: {
          center: sight.coordinates.coordinates,
          zoom: 17,
          pitch: 50,
          bearing: bearingStart
        }
      }
    })
    this.initMap();
  }

  /**
   * Jump to the previous sight
   */
  prevSight() {
    this.pageSettings.isAutoplay = false;
    if(this.activeIndex === 1 || this.activeIndex === 0){
      this.activeIndex = this.locations.length-1;
    } else{
      this.activeIndex--;
    }
    this.playBack(this.activeIndex);
  }

  /**
   * Jump to the next sight
   */
  nextSight() {
    this.pageSettings.isAutoplay = false;
    if(this.activeIndex === this.locations.length-1){
      this.activeIndex = 0;
    } else{
      this.activeIndex++;
    }
    this.playBack(this.activeIndex);
  }

  /**
   * Default after view init
   */
  ngAfterViewInit(){
    this.generateLocations();
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
    
  }

}
