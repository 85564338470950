import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TourPlatformService {
tourListFilterEvent = new BehaviorSubject(null);
tourListGroupActionEvent = new BehaviorSubject(null);
sightListFilterEvent = new BehaviorSubject(null);

constructor() { }


}
