<button type="button" class="btn btn-primary btn-icon rounded-circle" [class]="class" *ngIf="type === 'rounded' else btnBlock">
    <i class="fi-play" (click)="playAudio()" *ngIf="(state === 'idle' || state === 'stop') else stopState"></i>
    <ng-template #stopState>
        <i class="fi-minus" (click)="stopAudio()"></i>
    </ng-template>
  </button>
  <ng-template #btnBlock>
    <button class="btn btn-primary btn-sm active mb-3 me-2 mt-3" type="button" [class]="class">
        <span (click)="playAudio()" *ngIf="(state === 'idle' || state === 'stop') else stopState"><i class="fi-play me-1"></i> <ng-container i18n>Play preview</ng-container> </span>
        <ng-template #stopState>
            <span  (click)="stopAudio()"><i class="fi-minus me-1"></i> <ng-container i18n>Stop preview</ng-container> </span>
        </ng-template>
    </button>

  </ng-template>