<form [formGroup]="sightForm" (ngSubmit)="submitSight()">
  <div class="modal-header">
    <div class="row d-flex align-items-center w-100">
      <div class="col-8 d-flex align-items-center">
        <img src="../../../../../assets/img/logo/icon.png" width="60" class="me-3" alt="">
        <div *ngIf="type === 'create' else updateTourTitleBlock">
          <h3 class="mb-0" i18n>Create new sight</h3>
          <p class="text-muted mb-0" i18n>Create sights in seconds</p>
        </div>
        <ng-template #updateTourTitleBlock>
          <div>
            <h3 class="mb-0" i18n>Update sight</h3>
            <p class="text-muted mb-0" i18n>Update sights in seconds</p>
          </div>
        </ng-template>
      </div>
      <!-- <div class="col-4 text-end">
        <button
          class="btn btn-outline-secondary btn-sm me-1"
          type="button"
          (click)="modalRef.hide()"
        >
          Close
        </button>
        <button class="btn btn-success btn-sm" *ngIf="type === 'create'">Upload</button>
      </div> -->
    </div>
  </div>
  <div class="modal-body px-5">
    <div class="row mt-3">
      <div class="col-6">
        <h5 i18n>Sight details</h5>
        <!-- Tour name -->
        <div class="mb-3">
          <label class="form-label" i18n>Name of the sight</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name of the sight"
            formControlName="tourName"
            i18n-placeholder
          />
          <val-errors controlName="tourName" label="tourName">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n>Name is required </span>
            </ng-template>
          </val-errors>
          <ng-container class="text-danger">


            <div class="alert alert-danger mt-3 sfs-text-08" role="alert" *ngFor="let error of getErrorByErrorName('sightTourName')">
              {{error}}
               </div>
               
           
          </ng-container>
        </div>
        <!-- End of Tour name-->
        <!-- Tour description -->
        <div class="mb-3">
          <label class="form-label" i18n>Description of the sight</label>
          <textarea
            formControlName="tourDescription"
            class="form-control"
            placeholder="Description of the sight"
          ></textarea>
          <val-errors controlName="tourDescription" label="tourDescription">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n>Description is required </span>
            </ng-template>
          </val-errors>
          <ng-container class="text-danger">


            <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('sightTourDescription')">
              {{error}}
               </div>

           
          </ng-container>
        </div>
        <!-- End of Tour description-->
        <!-- Tour language -->
        <div class="mb-3">
          <label class="form-label" i18n>Language</label>
          <ng-select
            [searchable]="false"
            [clearable]="false"
            formControlName="languages"
            placeholder="Select language"
            i18n-placeholder
          >
            <ng-option
              *ngFor="let language of configService.availableLanguages"
              [value]="language.code"
            >
              <span
                class="flag-icon flag-icon-{{
                  language.code === 'en' ? 'gb' : language.code
                }} me-1"
              ></span
              >{{ language.name }}
            </ng-option>
          </ng-select>
          <val-errors controlName="languages" label="languages">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >Language is required
              </span>
            </ng-template>
          </val-errors>
        </div>
        <!-- End of Tour language-->
  
           <!-- Audio-->
           <div class="mb-3">
            <!-- Audioform -->
            <form class="d-none">
              <div class="form-group">
                <input type="file" id="audioSelector" accept="audio/mpeg"
                  (change)="uploadAudio($event, 'audio')">
              </div>
            </form>
   <!-- End of Audio form -->
            <div class="w-100" *ngIf="isAIBtnAvailable()">
              
              <label class="form-label w-100 d-flex">
                <span>Audio generation form</span>
              </label>

              <div class="d-flex">
                <button type="button" class="btn w-100 me-1 rounded-pill" [class]="pageSettings.activeVoiceMode === 'upload' ? 'btn-primary': 'btn-outline-secondary'" (click)="setUploadMode('upload')"> Upload</button>
                <button type="button" class="btn w-100 rounded-pill" [class]="pageSettings.activeVoiceMode !== 'upload' ? 'btn-primary': 'btn-outline-secondary'"  (click)="setUploadMode('ai'); openAIModal()"> Wingman <div class="badge fw-bold" [class]="pageSettings.activeVoiceMode === 'upload' ? 'bg-primary': 'bg-secondary'">AI</div></button>
              </div>
            </div>
         

            <label class="form-label w-100 d-flex mt-3">
              <span>Audio guide for the sight</span> <a href="javascript:void(0)" *ngIf="sightForm.getRawValue().audios.length && pageSettings.activeVoiceMode === 'upload'" class="ms-auto sfs-no-link sfs-text-08" (click)="openFileSelector('audioSelector')"><i class="fi fi-refresh me-1"></i>Change audio</a>
            </label>
          
            <div *ngIf="sightForm.getRawValue().audios.length; else noAudioBlock">
              <div class="ms-auto" *ngFor="let audio of sightForm.getRawValue().audios">
                <audio *ngIf="audio" class="w-100"  controls>
                  <source [src]="audio.value" type="audio/mpeg">
                Your browser does not support the audio element.
                </audio>
                <!-- <app-audio-preview *ngIf="audio" [src]="audio.url"></app-audio-preview>
                <button *ngIf="audio" type="button" class="btn btn-light-primary btn-icon rounded-circle ms-1 shadow"
                  (click)="removeSightAudio(audio)"><i class="fi-trash"></i></button> -->
              </div>
            </div>
            <ng-template #noAudioBlock>
              <div role="alert" class="fade d-flex mb-3 alert alert-info show">
                <i class="fi-alert-circle me-2 me-sm-3"></i>
                <p class="fs-sm mb-1">
                  The maximum audio size is 8 MB. Format: mp3.
                </p>
              </div>
              <div class="sfs-no-img-container" *ngIf="!pageSettings.loaders.uploadAudio else audioLoaderBlock">
                <div
                  class="text-center text-muted sfs-text-08"
                  (click)="openFileSelector('audioSelector')"
                >
                  <div class="img-circle-container">
                    <i class="fa fa-music fa-2x"></i>
                  </div>
                  <div class="mt-2">Click to upload (audio up to 8 mb)</div>
                </div>
              </div>
            </ng-template>
            <ng-template #audioLoaderBlock>
              <div class="w-100 text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </ng-template>
            <val-errors controlName="audios" label="audios">
              <ng-template class="text-danger" valError="required">
                <span class="text-danger sfs-text-08" i18n
                  >Audio guide is required
                </span>
              </ng-template>
            </val-errors>
            <ng-container class="text-danger">

              <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('sightAudio')">
                {{error}}
                 </div>

            
            </ng-container>
          </div>
          <!-- End of audio -->
  
        <!-- Images-->
        <div class="mb-5">
          <!-- Image form -->
          <form class="d-none">
            <div class="form-group">
              <input
                type="file"
                id="imageFileSelectorSight"
                accept="image/png, image/jpeg"
                multiple
                (change)="uploadImages($event)"
              />
            </div>
          </form>
          <label class="form-label w-100 d-flex">
            <span>Image of the sight</span>
            <a href="javascript:void(0)" *ngIf="sightForm.getRawValue().media.length" class="ms-auto sfs-no-link sfs-text-08" (click)="openFileSelector('imageFileSelectorSight')"><i class="fi fi-plus me-1"></i>Add more</a>
          </label>
          <div *ngIf="sightForm.getRawValue().media.length; else noMediaBlock">
            <div class="row">
              <div class="col-12">
                <div
                  class="row g-2 g-md-3"
                  ngxDroppable
                  [model]="sightForm.get('media').value"
                >
                  <div
                    class="col-xl-6 col-sm-6"
                    *ngFor="let item of sightForm.get('media').value; let i = index"
                    ngxDraggable
                    [model]="item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="gallery-item no-hover rounded-2"
                    >
                      <div
                        class="sfs-upload-media-preview-card sahdow-sm"
                        [ngStyle]="{ background: 'url(' + item.url + ')' }"
                      ></div>
                      <button
                        class="btn btn-sm btn-light-primary btn-icon shadow-sm rounded-circle zindex-5"
                        (click)="removeGalleryItem(i)"
                      >
                        <i class="fi-trash"></i>
                      </button>
                      <div class="ribbon ribbon-top-right" *ngIf="i === 0">
                        <span i18n>Cover</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noMediaBlock>
            <div role="alert" class="fade d-flex mb-3 alert alert-info show">
              <i class="fi-alert-circle me-2 me-sm-3"></i>
              <p class="fs-sm mb-1">
                The maximum photo size is 8 MB. Formats: jpeg, jpg, png. Put the
                main picture first. 
              </p>
            </div>
            <div class="sfs-no-img-container">
              <div
                class="text-center text-muted sfs-text-08"
                (click)="openFileSelector('imageFileSelectorSight')"
              >
                <div class="img-circle-container">
                  <i class="fa fa-camera fa-2x"></i>
                </div>
                <div class="mt-2">Click to upload (images up to 8 mb)</div>
              </div>
            </div>
          </ng-template>
          <val-errors controlName="media" label="media">
            <ng-template class="text-danger" valError="required">
              <span class="text-danger sfs-text-08" i18n
                >At least 2 images are required
              </span>
            </ng-template>
            <ng-template class="text-danger" valError="minlength">
              <span class="text-danger sfs-text-08" i18n
                >At least 2 images are required
              </span>
            </ng-template>
          </val-errors>
          <ng-container class="text-danger">


            <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('sightCoverImg')">
              {{error}}
               </div>

               
           
          </ng-container>
          <ng-container class="text-danger">


            <div class="alert alert-danger mt-3 sfs-text-08" role="alert" *ngFor="let error of getErrorByErrorName('sightGalleryImg')">
              {{error}}
               </div>


      
          </ng-container>
        </div>
        <!-- End of images -->

        <!-- Map -->
        <div class="mb-5">
          <label class="form-label d-flex align-items-center">
            <span>Location of the sight</span>
            <val-errors class="ms-auto" controlName="coordinates" label="coordinates">
              <ng-template class="text-danger" valError="required">
                <span class="text-danger sfs-text-08" i18n>Exact location is required</span>
              </ng-template>
            </val-errors>
          </label>
          <app-sight-map [initialPos]="sightForm.get('coordinates').value" (markerPositionUpdated)="markerPositionUpdated($event)"
          [marker]="sightForm.get('coordinates').value"></app-sight-map>
        </div>
        <!-- End of Map-->

          <!-- Comment list -->
          <div class="mb-5" *ngIf="!authService.user.roles.includes('creator')">
            <app-comment-list *ngIf="sight" [sight]="sight"></app-comment-list>
          </div>
          <!-- End of Comment list -->

          <div class="mb-5" *ngIf="!authService.user.roles.includes('creator')">
             <!-- Log list -->
            <app-log-list *ngIf="sight" [sight]="sight"></app-log-list>
            <!-- End of Sight list -->
          </div>
      </div>
      <div class="col-5 offset-1">
        <h5 i18n>Sight preview</h5>
          <app-sight-preview [sight]="sightForm.getRawValue()" [errors]="errors"></app-sight-preview>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-between">
   
    <button
    class="btn btn-outline-secondary btn-sm me-1"
    type="button"
    (click)="modalRef.hide()"
  >
    Close
  </button>
  <div>
    <button
  class="btn btn-outline-secondary btn-sm me-1"
  type="button"
  *ngIf="type !== 'create'"
  (click)="copyToClipboard()" i18n
>
 <i class="fa fa-copy"></i>    Copy sight link
</button>
  <button class="btn btn-success btn-sm" *ngIf="type === 'create' else updateBtnBlock">Upload</button>
  </div>
  
  <ng-template #updateBtnBlock>
    <button class="btn btn-primary btn-sm">Update</button>

  </ng-template>
  </div>
 
  
</form>


<!-- AI modal -->
<ng-template #aiModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Generate with AI</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="aiModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">


      <div class="mb-3">
        <label class="form-label" i18n>Voice</label>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          placeholder="Select voice"
          [(ngModel)]="aiVoiceSettings.selectedVoice"
          (change)="refreshAudio()"
          i18n-placeholder
        >
          <ng-option
            *ngFor="let voice of voices"
            [value]="voice"
          >
            {{ voice.name }} - {{ voice?.labels?.age }} {{ voice?.labels?.accent}} {{ voice?.labels?.gender}} - {{voice?.labels?.description}}
          </ng-option>
        </ng-select>
      </div>
      <div class="mb-3" *ngIf="aiVoiceSettings.selectedVoice">
        <label class="form-label" i18n>Preview of the voice</label>
        <audio id="exampleVoice" class="w-100"  controls>
            <source [src]="aiVoiceSettings.selectedVoice.preview_url" type="audio/mp3">
            Your browser does not support the audio element.
        </audio>
      </div>
      <div class="mb-3">
        <label class="form-label" i18n>Description of the sight</label>
        <textarea
          [(ngModel)]="aiVoiceSettings.textToGenerate"
          class="form-control"
          placeholder="Description of the sight"
          (change)="aiDescriptionChanged($event)"
        ></textarea>
        
        <ng-container class="text-danger">
          <div class="alert alert-danger mt-3 sfs-text-08" role="alert"  *ngFor="let error of getErrorByErrorName('sightTourDescription')">
            {{error}}
             </div>
        </ng-container>

      </div>
    </div>

  <div class="modal-footer">
    <div class="text-success sfs-text-08" *ngIf="aiVoiceSettings.textToGenerate.length < characterLimit else exceedBlock">{{characterLimit-aiVoiceSettings.textToGenerate.length}} characters left</div>
    <ng-template #exceedBlock>
      <div class="text-danger sfs-text-08">Exceeds the limit with {{aiVoiceSettings.textToGenerate.length-characterLimit}} characters</div>
    </ng-template>
    <button type="button" class="btn btn-primary btn-sm ms-auto"  (click)="generateVoice()" [disabled]="!aiVoiceSettings.selectedVoice && aiVoiceSettings.textToGenerate.length>characterLimit">Generate</button>
  </div>
</ng-template>
<!-- End of AI modal -->