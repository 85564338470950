import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModalComponent } from './login-modal.component';
import { SocialLoginModule } from '../../../social-login/social-login.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    LoginModalComponent
  ],
  imports: [
    CommonModule,
    SocialLoginModule,
    FormsModule,
    RouterModule,
  ], 
  exports: [LoginModalComponent]
})
export class LoginModalModule { }
