<section class="container mb-sm-5 mb-4 pb-lg-4" [class]="supportClass" *ngIf="cities && cities.length">
    <div class="d-sm-flex align-items-center justify-content-between mb-4 pb-2">
        <h2 class="h3 mb-sm-0" i18n>Available destinations</h2>
    </div>
    <div class="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside">
        <div [attr.class]="class">
            <div class="tns-item city tns-slide-cloned" *ngFor="let city of cities" aria-hidden="true" tabindex="-1" (click)="navigateWithCity(city)">
                <div class="position-relative">
                    <div class="position-relative mb-3 tns-item-cover city after shadow-sm" [class.comingSoon]="!city.numberOfTours" [ngStyle]="{'background-image': 'url('+city.cover+')'}">
                        <div class="text-center" *ngIf="city.numberOfTours else comingSoonBlock">
                            <h3 class="mb-2 fs-lg text-truncate text-white"><a class=" text-truncate text-white sfs-no-link" href="javascript:void(0)">{{city.name}}</a></h3>
                            <div class="text-white sfs-text-08 fw-bold"><i class="fi-map-pin mt-n1 me-1 fs-base text-white align-middle"></i>{{city.country}}</div>
                        </div>
                        <ng-template #comingSoonBlock>
                            <div class="text-center">
                                <h3 class="mb-2 fs-lg text-truncate text-white">Coming soon</h3>
                                <div class="text-white sfs-text-08 fw-bold"><i class="fi-map-pin mt-n1 me-1 fs-base text-white align-middle"></i>{{city.name}}, {{city.country}}</div>
                            </div>
                        </ng-template>
                    </div>
                 
                  </div>
              </div>
          </div>
    </div>
</section>