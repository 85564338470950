import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart.component';
import {NgApexchartsModule} from 'ng-apexcharts';

@NgModule({
  imports: [
    CommonModule,
    NgApexchartsModule
  ],
  declarations: [ChartComponent],
  exports: [ChartComponent]
})
export class ChartModule { }
