import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class InfoService {
  routePath = 'infos';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  getInfoByCity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getInfoByCity/`);
    return this.httpService.post<any>(url,body);
  }

  getInfoByCityRaw(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getInfoByCityRaw/`);
    return this.httpService.post<any>(url,body);
  }

  updateInfo(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/updateInfo/`);
    return this.httpService.post<any>(url,body);
  }

  addInfoToCity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/addInfoToCity/`);
    return this.httpService.post<any>(url,body);
  }

  logInfoPopularity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/logInfoPopularity/`);
    return this.httpService.post<any>(url,body);
  }

  removeInfoFromCity(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/removeInfoFromCity/`);
    return this.httpService.post<any>(url,body);
  }

  



  

}
