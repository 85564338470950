import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SightPreviewComponent } from './sight-preview.component';
import { NoDataBlockModule } from '../no-data-block/no-data-block.module';
import { AudioPreviewModule } from '../audio-preview/audio-preview.module';


@NgModule({
  declarations: [
    SightPreviewComponent
  ],
  imports: [
    CommonModule,
    NoDataBlockModule,
    AudioPreviewModule
  ], 
  exports: [SightPreviewComponent]
})
export class SightPreviewModule { }
