import { Component, Input, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import {TourService, UserStateService } from '@services';
import { take } from 'rxjs/operators';
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common'; 
import { ConfigService } from '@core/services/config.service';
import { LocaleService } from '@core/services/misc/locale.service';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {
  user;
  authSubscription;
  tours = [];
  markers = [];
  selectedCategories = [];
  isFactoryMade = false;
  selectedLanguage = {
    code: "en",
    name: "English"
  };
  city;
  map;
  isMapLoaded = false;
  languages = [];
  pageSettings = {
    isList: true,
    selectedCity: null
  };
  locales = {
    NoTours: $localize `No available tours`,
    TryToModify: $localize `Try to modify your search preferences.`,
    All: $localize `All`,
  }
  constructor(private tourService: TourService, private route: ActivatedRoute, public configService: ConfigService, private userStateService: UserStateService, private location: Location, @Inject(LOCALE_ID) public locale: string, private localeService: LocaleService) {}

  /**
   * Gets tours from the server
   * @param getLanguages if true, will get languages for the tours
   */
  getTours(getLanguages?){
    this.tourService.filterTours({categories: this.selectedCategories, locale: this.selectedLanguage.code, city: this.city, isFactoryMade: this.isFactoryMade, isSightSearch: false, sort: {name: 'rating', order: -1}}).pipe(take(1)).subscribe((x)=>{ 
      this.tours = x;
    });
    if(getLanguages){
      this.getLanguages();
    }
  }
  /**
   * Gets available languages
   */
  getLanguages(){
    this.tourService.getLanguages({categories: this.selectedCategories, city: this.city}).pipe(take(1)).subscribe((languages)=>{ 
      this.languages = _.map(languages, (language)=>{ 
        let lang = _.find(this.configService.availableLanguages, (x)=>{return x.code  === language}) ;
        if(lang && lang.name){
          return lang;
        } else{
          return {
            code: language
          }
        }
      })
    })
  }
  /**
   * Fires category filter event based on the category id
   * @param $event categoryId
   */
  categoryChanged($event){
    if($event.categoryId){
      if(this.selectedCategories.indexOf($event.categoryId) !== -1){
        _.remove(this.selectedCategories, (x)=>{return x === $event.categoryId});
      } else{
        this.selectedCategories.push($event.categoryId)
      }
    }
  
    this.isFactoryMade = $event.isFactoryMade;
    this.getTours();
  }


  /**
   * Ersases all markers from the map
   */
  eraseMarkers(){
    _.forEach(this.markers, (marker)=>{
      marker.remove();
    });
    this.markers = [];
  }

  /**
   * Draw markers on the map
   * @param drawName if true, will draw the name of the markers
   * @param fitBounds if true, will fit the map to the bounds of the markers
   */
  drawMarkers(drawName?, fitBounds?){
    this.eraseMarkers();

    _.forEach(this.tours, (x, i)=>{
      const el = document.createElement('div');
      console.log(x)
      if(x.categoriesMapped && x.categoriesMapped.length){
        let txt = `<div class="sfs-marker light sfs-text-black"><i class="${x.categoriesMapped[0].icon}"></i></div> <div class="text-container shadow">${drawName ? (x.tourName ? (x.tourName.value + ' (' + x.credit + '<img src="../../../assets/img/icons/token.svg" width="15" alt="">)</div>') : '') : ''}`;
        el.innerHTML = txt;
        el.className = 'sfs-marker-container light-container';
       let marker = new mapboxgl.Marker(el,{draggable: false}).setLngLat(x.coordinates.coordinates).addTo(this.map);
       this.markers.push(marker);
       marker.getElement().addEventListener('click', () => { 
        window.open('/'+this.locale+'/tour/'+x._id, '_blank');
      }); 
      }
     
    })
    if(this.tours.length > 0 && fitBounds){
      this.fitMapToBounds();
    }
  }

  /**
   * Filters tours based on the city
   * @param city city id
   */
  filter(city){
    this.city = city._id;
    this.location.replaceState("/tours?city="+this.city);
    this.getTours(true);
  }

  /**
   * Filters tours based on the language
   * @param language language code
   */
  filterLanguage(language){
    this.selectedLanguage = language;
    this.getTours(true);
  }

  /**
   * Detects city from the url
   * @returns true if city is selected
   */
  detectCity(){
    if(this.city){
      let city = _.find(this.configService.cities, (x)=>{return x._id == this.city});
      if(city){
       this.pageSettings.selectedCity = city;
       return true;
      } else{
        return false;
      }
    } else{
      return false;
    }
  }

  /**
   * Fits the map to the bounds of the markers
   */
  fitMapToBounds(){
    this.isMapLoaded = false;
    let bounds = new mapboxgl.LngLatBounds();
    this.markers.forEach(function(feature) {
        bounds.extend([feature.getLngLat().lng,feature.getLngLat().lat]);
    });
    
    this.map.fitBounds(bounds, {padding: 50, duration: 500});
    setTimeout(()=>{
      this.isMapLoaded = true;
    },1000)
  }

  /**
   * Initializes the map
   */
  initMap(){
      mapboxgl.accessToken = environment.mapBoxAccessToken;
      this.map = new mapboxgl.Map({
        container: 'tourMap',
        style: environment.mapbox.mapStyle,
        zoom: 10,
        maxZoom:15,
        minZoom:4,
        center: this.tours[0].coordinates.coordinates
      });
      this.map.on('zoom', () => {
        const currentZoom = this.map.getZoom();
        if(currentZoom>11 && this.isMapLoaded){
          this.drawMarkers(true)
        } 
      });
    this.drawMarkers(true);
  }

  /**
   * Toggles list view / map view
   */
  toggleMap(){
    this.pageSettings.isList = !this.pageSettings.isList;
    window.scrollTo(0,0);
    if(!this.pageSettings.isList){
      setTimeout(()=>{
        this.initMap();
      },50)
    }
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {

    const activeLanguage = _.find(this.localeService.locales, (language: any)=>{return language.locale === this.locale});
    if(activeLanguage){
      this.selectedLanguage = {...activeLanguage, ...{code: activeLanguage.shortHand}}
    }
 
      this.route.queryParams
      .subscribe(params => {
        if(params.categoryId){
          this.selectedCategories.push(params.categoryId);
          this.getTours(true);
        } else if(params.city){
          this.city = params.city;
          this.getTours(true);
        } else{
          this.getTours(true);
        }
        
      }
    );
   
    
  }

}
