import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class LogsService {
  routePath = 'logs';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  getLogs(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getLogs/`);
    return this.httpService.post<any>(url,body);
  }

  createLog(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/createLog/`);
    return this.httpService.post<any>(url,body);
  }

  

  



  

}
