import { AfterViewInit, Component, Input, OnInit, SimpleChange } from '@angular/core';
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { UtilsService } from '@services';
@Component({
  selector: 'app-tour-map',
  templateUrl: './tour-map.component.html',
  styleUrls: ['./tour-map.component.scss']
})
export class TourMapComponent implements OnInit, AfterViewInit {
  @Input() sights;
  @Input() type = 'edit';
  map;
  markers = [];
  sightMarker;

  constructor(private utilsService: UtilsService) { }

/**
   * Remove markers from the map
   */
  removeMarkers(){
    _.forEach(this.markers, (x)=>{
      if(x){
        x.remove();
      }
    });
  }
  /**
   * Initialize the map
   */
  initMap(){
    console.log(this.sights)
    mapboxgl.accessToken = environment.mapBoxAccessToken;
    this.map = new mapboxgl.Map({
      container: 'tour_map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 14,
      center: this.sights.length ? this.sights[0].coordinates.coordinates : [-73.9893682, 40.7589626]
    });
    if(this.type === 'detailed'){
      this.map.scrollZoom.disable();
      this.map.addControl(new mapboxgl.NavigationControl());
    }
    this.drawMarkers();
  }

  /**
   * Draws markers on the map
   */
  drawMarkers(){
    this.removeMarkers();
    if(this.sights && this.sights.length){
      _.forEach(this.sights, (x, i)=>{
        const el = document.createElement('div');
        el.innerHTML = '<div class="sfs-marker sfs-text-black">'+(i+1)+'.</div>';
        el.className = 'sfs-marker-container ';
       let marker = new mapboxgl.Marker(el,{draggable: false}).setLngLat(x.coordinates.coordinates).addTo(this.map);
       this.markers.push(marker);
      })
      if(this.sights.length > 0){
        this.fitMapToBounds();
      }
    }
   
  }

  /**
   * Fits the map to the bounds of the markers
   */
  fitMapToBounds(){
    let bounds = new mapboxgl.LngLatBounds();
    this.markers.forEach(function(feature) {
        bounds.extend([feature.getLngLat().lng,feature.getLngLat().lat]);
    });
    if(this.map){
      this.map.fitBounds(bounds, {padding: 50, duration: 1000});
    }
  }

  /**
   * Zoom to the selected sight
   * @param sights sights to zoom to
   */
  zoomToSelected(sights){
    let sight = _.find(sights, (x)=>{return x.selected === true});
    if(sight){
      
      this.utilsService.scrollTo('#tour_map',60);
      if(this.map){
        this.map.flyTo({center: sight.coordinates.coordinates, zoom:12});
      }
    }
  }

  /**
   * Default change handler
   * @param changes changes object
   */
  ngOnChanges(changes: any) {
    
    this.zoomToSelected(changes.sights.currentValue);
    this.drawMarkers();
    
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values
    
}

  /**
   * Default after view init handler
   */
  ngAfterViewInit(){
    this.initMap();
  }

  /**
   * Default component init handler
   */
  ngOnInit(): void {
   
  }

  

}
