import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinifiedSightListComponent } from './minified-sight-list.component';
import { AudioPreviewModule } from '../audio-preview/audio-preview.module';

@NgModule({
  declarations: [
    MinifiedSightListComponent
  ],
  imports: [
    CommonModule,
    AudioPreviewModule
  ],
  exports: [MinifiedSightListComponent]
})
export class MinifiedSightListModule { }
