import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserProfile } from '../../../../../../shared/models';
import { LoginResponse } from '../../../../../../shared/models/login-response';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiService {
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  uploadFile(body): any {
    const url = this.getApiEndpoint(`file/uploadFile/`);
    return this.httpService.post(url,body);
  }

  uploadFileAndProcess(body): any {
    const url = this.getApiEndpoint(`file/uploadFileAndProcess/`);
    return this.httpService.post(url,body);
  }

  login(username: string, password: string): Observable<LoginResponse> {
    const url = this.getApiEndpoint(`login`);
   
    return this.httpService.post<LoginResponse>(url, {
      username,
      password,
    });
  }

  registerOrLogin(username: string, password: string, provider, registerForm): Observable<any> {
    const url = this.getApiEndpoint(`registerOrLogin`);
    return this.httpService.post<any>(url, {
      username,
      password,
      provider,
      registerForm
    });
  }

  socialLogin(provider: string, authToken: string): Observable<LoginResponse> {
    const url = this.getApiEndpoint(`social-login/${provider}`);
    return this.httpService.get<LoginResponse>(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        access_token: `${authToken}`,
      },
      withCredentials: true,
    });
  }

  register(user: UserProfile): Observable<UserProfile> {
    const url = this.getApiEndpoint('register/');
    return this.httpService.post<UserProfile>(url, user);
  }

  logout(): Observable<void> {
    const url = this.getApiEndpoint('logout/');
    return this.httpService.get<void>(url);
  }

  getProfile(): Observable<UserProfile> {
    const url = this.getApiEndpoint(`profile/`);
    return this.httpService.get<UserProfile>(url);
  }

  getConfig(): Observable<any> {
    const url = this.getApiEndpoint(`getConfig/`);
    return this.httpService.get<any>(url);
  }

  getInformationCategories(): Observable<any> {
    const url = this.getApiEndpoint(`getInformationCategories/`);
    return this.httpService.get<any>(url);
  }
}
