
<!-- Sights -->
<div *ngIf="sights.length">
    <div class="card card-hover tour-card card-horizontal border-0 shadow-sm mb-4" *ngFor="let sight of sights; let i = index">
        <div class="sfs-marker">{{i+1}}.</div>
        <a class="card-img-top"
            href="javascript:void(0)" style="background-image: url({{sight.media[0].url}});">
            <div class="position-absolute start-0 top-0 pt-3 ps-3"></div>
        </a>
        <div class="card-body position-relative pb-3">
           
         
            <div class="d-flex align-items-center h-100">
                <div>
                    <h3 class="h6 mb-2 fs-base"><a class="nav-link" href="javascript:void(0)">{{sight?.name}}</a></h3>
                    <p class="mb-2 fs-sm text-muted text-truncated">{{sight.description}}</p>
                    <app-audio-preview [src]="sight.audios?.value" [type]="'button'" [playMode]="'preview'"></app-audio-preview>
                    <button class="btn btn-primary btn-sm active  btn-icon"(click)="selectSight(sight)"><i class="fi-map-pins"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 justify-content-center align-items-center" (click)="clickedMoreAttractions()" *ngIf="sights.length>2 && (totalNumberOfSights-sights.length)>0">
        <a href="javascript:void(0)" class="sfs-text-09"><i class="fi-map-pins me-1"></i>{{totalNumberOfSights-sights.length}} <ng-container i18n> more attractions in the actual tour</ng-container></a>
    </div>
</div>
