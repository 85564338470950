import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NotyService } from './noty.service';
import { TourService } from '../requests/tour.service';
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';

@Injectable()
export class FavoritesService {
  constructor(private notyService: NotyService, private tourService: TourService, private authService: AuthService) {}

  toggleLike(id, isLiked){
    if (isLiked) {
        this.notyService.dropNoty('info', $localize `Added to favourites`);
      } else {
        this.notyService.dropNoty('info', $localize `Removed from favourites`);
      }
      this.tourService.like({ id }).pipe(take(1)).subscribe((x) => {
        this.authService.user.likes.push(id);
        this.authService.refreshUser(this.authService.user);
      });
  }

  isLiked(user, id){
    if(user){
      if(_.find(user.likes, (x)=>{
        return x.tour === id;
      })){
        return true;
      } else{
        return false;
      }
    }
  }
}
