<div class="container px-0 py-5">
  <div class="row mx-0 align-items-center">
    <div class="col-md-6 colsm-12 border-end-md p-4 p-sm-5">
      <div class="sfs-close-container sfs-hover" (click)="closeModal()"><i class="fa fa-times"></i></div> 
      <h2 class="h3 mb-4 mb-sm-5 sfs-text-black text-center text-sm-start"><ng-container i18n>Hey there!</ng-container><br><ng-container i18n>Welcome back.</ng-container></h2>
      <img class="d-block mx-auto" src="../../../../assets/img/logo/icon.png" width="250" alt="Illustartion">
      <div class="mt-4 mt-sm-5"><span i18n>Don't have an account?</span>&nbsp;<a href="javascript:void(0)" i18n
          [routerLink]="['/register']" (click)="closeModal()" i18n>Sign up here</a></div>
    </div>
    <div class="col-md-6 col-sm-12 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
      <!-- Login block -->
      <div *ngIf="pageSettings.isLogin else forgotPswdBlock">
        <!-- Social login -->
       <!--  <app-social-login-button [type]="'login'"></app-social-login-button> -->
        <!-- Login form -->
      <!--   <div class="d-flex align-items-center py-3 mb-3">
          <hr class="w-100">
          <div class="px-3" i18n>Or</div>
          <hr class="w-100">
        </div> -->
        <form class="needs-validation" name="loginForm">
          <div class="mb-4">
            <label class="form-label mb-2" for="signin-email" i18n>Email address</label>
            <input class="form-control" #focusInput name="email" tabindex="1" type="email" autofocus id="signin-email" [(ngModel)]="email"
              placeholder="Enter your email" i18n-placeholder required>
          </div>
          <div class="mb-4">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <label class="form-label mb-0" for="signin-password"  i18n>Password</label><a class="fs-sm"
                 (click)="pageSettings.isLogin = false" href="javascript:void(0)" i18n>Forgot password?</a>
            </div>
            <div class="password-toggle">
              <input class="form-control" name="password" tabindex="2" type="password" [(ngModel)]="password" id="signin-password"
                placeholder="Enter password" i18n-placeholder required>

            </div>
          </div>
          <button class="btn btn-primary btn-lg rounded-pill w-100" tabindex="3" [disabled]="pageSettings.loaders.login" (click)="onLoginClick()" type="submit" i18n>Sign
            in</button>
        </form>
      </div>
      <!-- End of Login block -->
      <ng-template #forgotPswdBlock>
        <form class="needs-validation" name="loginForm">
          <div class="mb-4">
            <label class="form-label mb-2" for="signin-email" i18n>Email address</label>
            <input class="form-control" name="email" type="email" id="signin-email" [(ngModel)]="email"
              placeholder="Enter your email" i18n-placeholder required>
          </div>
          <button class="btn btn-primary btn-lg rounded-pill w-100" (click)="obtainNewPassword()" type="submit" i18n>Request new password</button>
          <div class="row">
            <div class="col-12 text-center">
              <a class="fs-sm" (click)="pageSettings.isLogin = true" href="javascript:void(0)" i18n>Back to login</a>

            </div>
          </div>
        </form>
      </ng-template>

    </div>
  </div>

</div>