import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { PaymentService, UserStateService } from '@services';
import _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  @Input() tour;
  @Input() user;
  test;
  pageSettings = {
    tab: 'info',
    activeTabIndex: 0,
    loaders: {
      book: false
    }
  }
  email;
  bookObj = {
    noOfParticipants: 1,
    language: 'en',
    inviteCode: null,
    participants: [],
  };
  locales = {
    NoMates: $localize `No tourmates added`,
    SetEmail: $localize `You can add tourmates by setting their email addresses`,
    Next: $localize `Next`,
    Checkout: $localize `Checkout`,
    Credits: $localize `Credits`,
  }
  constructor(private modalService: BsModalService, public userStateService: UserStateService, private paymentService: PaymentService, public configService: ConfigService) { }

  /**
   * Closes the modal
   * @param reason reson for closing the modal
   */
  closeModal(reason?){
    if(reason){
      this.modalService.setDismissReason(reason);
    }
    this.modalService.hide();
  }

  /**
   * Checks if the email address is valid
   * @returns true if the email address is valid
   */
  isValidEmail(){
    return String(this.email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  /**
   * Adds a user to the list of participants
   */
  addUser(){
    if(!_.find(this.bookObj.participants, (user)=>{return user.email === this.email})){
      this.bookObj.participants.push({email: this.email})
      this.email = null;
    }
  }
  /**
   * Removes a user from the list of participants
   * @param user user to remove from the list of participants
   */
  removeUser(user){
    _.remove(this.bookObj.participants, (participant)=>{return participant.email === user.email});
  }

  /**
   * Activates the tab with the given index
   * @param tab tab to activate
   */
  loadTab(tab: number){
    this.pageSettings.activeTabIndex = tab+1;
  }

  /**
   * Jumps to the tab with the given index
   * @param tab tab to activate
   */
  next(tab?){
    if(this.bookObj.noOfParticipants > 1 && this.pageSettings.activeTabIndex != 1){
      this.pageSettings.activeTabIndex = this.pageSettings.activeTabIndex+1;
      this.staticTabs.tabs[this.pageSettings.activeTabIndex].active = true;
    } else if(this.bookObj.noOfParticipants > 1 && this.pageSettings.activeTabIndex == 2){
      this.checkout();
    } else{
      this.checkout();
    }
  }
  /**
   * Performs the book action
   */
  checkout(){
    this.pageSettings.loaders.book = true;
    this.paymentService.book({tour: this.tour._id, numberOfParticipants: this.bookObj.noOfParticipants, inviteCode: this.bookObj.inviteCode, price: this.bookObj.noOfParticipants*this.tour.credit, language: this.bookObj.language, participants: this.bookObj.participants}).pipe(take(1)).subscribe((x)=>{
    
     if(x.success){
      this.closeModal('refresh');
     } else{
      this.closeModal('cancelBook');
     }
     this.pageSettings.loaders.book = false;
     
    })
  }
  /**
   * Default component initialization
   */
  ngOnInit(): void {
    this.bookObj.language = this.tour.languages[0].code;
  }

}
