import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class IssueService {
  routePath = 'issue';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  getActiveIssues(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getActiveIssues/`);
    return this.httpService.post<any>(url,body);
  }

  markIssueSolved(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/markIssueSolved/`);
    return this.httpService.post<any>(url,body);
  }

 

}
