<div class="position-relative" (click)="navigateToListing($event)">
  <button *ngIf="hasLike"
    class="btn btn-icon sfs-like-btn btn-xs text-primary btn-light-primary rounded-circle position-absolute top-0 end-0 m-3 zindex-5 bookmark-icon"
    type="button" [class.isLiked]="isLiked"><i class="bookmark-icon" [class]="isLiked ? 'fi-heart-filled' : 'fi-heart'"></i></button>
  <div class="position-relative mb-3 tns-item-cover shadow-sm"
    [ngStyle]="{'background-image': 'url('+tour?.media[0]?.url+')'}">
    <div class="sfs-verified-block d-flex align-items-center shadow" *ngIf="tour.isFactoryTour">
      <img src="../../../assets/img/cards/verified.svg" width="25" height="25" class="me-1"> <ng-container i18n> Wingman made</ng-container>
    </div>
  </div>
  <h3 class="mb-2 fs-lg d-flex align-items-center"><a class="nav-link stretched-link text-truncate" href="javascript:void(0)"
      >{{tour.tourName.value}}</a>&nbsp;<img src="../../../assets/icons/duotone/Interface/Star.svg" class="ms-auto" width="15">&nbsp;<b class="sfs-text-09">{{tour.rating.toFixed(1)}}</b></h3>
  <ul class="list-inline mb-0 fs-xs d-flex align-items-center">
 
    <li class="list-inline-item pe-1 d-flex align-items-center"><img src="../../../../assets/img/icons/token.svg" width="20" class="me-1"><b>{{tour.credit}}&nbsp;<ng-container i18n>credits</ng-container></b> </li>
    <li class="list-inline-item pe-1 d-flex align-items-center" *ngIf="type !== 'nearby' else nearbyDistanceBlock"><img src="../../../assets/icons/duotone/Contacts/Map.svg" width="15">&nbsp;{{type !== 'favourites' ? tour.city.name : (tour.address ? tour.address.city : tour.city.name)}}</li>
    <ng-template #nearbyDistanceBlock>
      <li class="list-inline-item pe-1"><img src="../../../assets/icons/duotone/Contacts/Map-Marker.svg" width="15">&nbsp;<ng-container i18n>In</ng-container> {{tour.distance}} km</li>
    </ng-template>
  </ul>
</div>