import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SightMapComponent } from './sight-map.component';



@NgModule({
  declarations: [
    SightMapComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    SightMapComponent
  ]
})
export class SightMapModule { }
