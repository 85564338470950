import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotyService } from '@services';
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
  selector: 'app-minified-sight-list',
  templateUrl: './minified-sight-list.component.html',
  styleUrls: ['./minified-sight-list.component.scss']
})
export class MinifiedSightListComponent implements OnInit {
  @Output() sightSelected = new EventEmitter();
  @Input()
  get sights(){ return this._sights; }
  set sights(sights) {
    this._sights = _.map(sights.slice(0,3), (sight) => {
      let tourName = _.find(sight.tourName, (t) => { return t.languages === 'en' })
      let tourDescription = _.find(sight.tourDescription, (t) => { return t.languages === 'en' })
      tourDescription = tourDescription ? tourDescription  : sight.tourDescription.value;
      return _.merge(sight, {
        fromNow: moment(sight.created).fromNow(),
        name: tourName ? tourName.value : sight.tourName.value,
        description: (tourDescription && tourDescription.length) > 170 ? (tourDescription.substr(0,170) + '...') : tourDescription ,
        originalDescription: tourDescription
      })
    })
    
  }
  private _sights = [];
  @Input() totalNumberOfSights = 0;
  constructor(private notyService: NotyService) { }

  /**
   * Performs the action (event emitter) when a sight is selected
   * @param sight sight to be selected
   */
  selectSight(sight){
    this.sightSelected.emit({id: sight._id});
  }

  /**
   * Drops error message if a user is not logged in
   */
  clickedMoreAttractions(){
    this.notyService.dropNoty('info',$localize `Attractions will be available after your purchase the tour.`)
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
  }

}
