import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToursComponent } from './tours.component';
import { TourCardModule } from 'src/app/modules/tour-card/tour-card.module';
import { LandingCategoriesModule } from 'src/app/modules/landing-categories/landing-categories.module';
import { NoDataBlockModule } from 'src/app/modules/no-data-block/no-data-block.module';
import { ToursCardListModule } from 'src/app/modules/tours-card-list/tours-card-list.module';
import { TransactionsModule } from '../tour-platform/transactions/transactions.module';




@NgModule({
  declarations: [
    ToursComponent
  ],
  imports: [
    CommonModule,
    TourCardModule,
    TransactionsModule,
    LandingCategoriesModule,
    NoDataBlockModule,
    ToursCardListModule
  ],
  exports: [ToursComponent]
})
export class ToursModule { }
