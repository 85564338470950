<section class="mb-sm-5 mb-4 pb-lg-4" *ngIf="tours && tours.length">
    <div class="d-sm-flex align-items-center justify-content-between">
        <h2 class="h3 mb-sm-0" i18n>Similar tours nearby</h2>
    </div>
    <div class="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside mt-3">
        <div [attr.class]="class">
            <div class="tns-item tns-slide-cloned" *ngFor="let tour of tours" aria-hidden="true" tabindex="-1">
              <app-tour-card [tour]="tour" [hasLike]="false"  [isLiked]="false" [type]="'search'"></app-tour-card>  
              </div>
          </div>
    </div>
</section>