import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import {  PaymentService } from '@services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @Input() userId;
  pageSettings = {
    navigate: false
  }
  constructor(private modalService: BsModalService, private paymentService: PaymentService, public configService: ConfigService) { }

  /**
   * Closes the modal
   * @param reason reson for closing the modal
   */
  closeModal(reason?){
    if(reason){
      this.modalService.setDismissReason(reason);
    }
    this.modalService.hide();
  }

  /**
   * Selects the payment package
   * @param id id of the package
   */
  selectPackage(id, userId?){
    this.pageSettings.navigate = true;
    if(this.userId){
      this.paymentService.createSessionDirectly({productId: id, userId: this.userId ? this.userId : null}).pipe(take(1)).subscribe((x)=>{
        if(x.url){
          window.location.href = x.url;
        }
        this.pageSettings.navigate = false;
      })
    } else{
      this.paymentService.createSession({productId: id}).pipe(take(1)).subscribe((x)=>{
        if(x.url){
          window.location.href = x.url;
        }
        this.pageSettings.navigate = false;
      })
    }
     
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
    
  }

}
