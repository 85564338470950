import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-audio-preview',
  templateUrl: './audio-preview.component.html',
  styleUrls: ['./audio-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPreviewComponent implements OnInit {
  audio;
  state = 'idle';
  @Input() src;
  @Input() type = "rounded";
  @Input() class: any;
  @Input() playMode = "live";
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    
  }

  /**
   * Plays the audio file
   */
  playAudio(){
    this.state = 'playing';
    this.audio.play();
    if(this.playMode === 'preview'){
      setTimeout(() => {
        this.endAudio();
      },3000)
    }
  }
  /**
   * End the audio file
   */
  endAudio(){
    this.state = 'idle';
    this.audio.pause();
    this.audio.currentTime = 0;
    this.changeDetectorRef.markForCheck();
  }
  /**
   * Stops the audio file
   */
  stopAudio(){
    this.state = 'stop';
    this.audio.pause();
  }
  /**
   * Default component initialization
   */
  ngOnInit(): void {
    
    this.audio = new Audio(this.src);
    this.audio.addEventListener("ended", ()=>{
      this.state = 'idle';
    });
  }

}
