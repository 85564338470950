import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { LoginResponse, UserProfile } from '../../../../../../shared/models';
import { ApiService } from '@services';
import { Router } from '@angular/router';
import { UserStateService } from './userState.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from '../../../modules/auth/login-modal/login-modal.component';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  modalRef;
  _user: any;
  public userChanged: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
 
  private _loginChecked: boolean;

  get user(): any {
    return this._user;
  }

  set user(user: any) {
    if (user !== this._user) {
      if (user !== null) {
        this.loginChecked = true;
      }
      this._user = user;
      this.userChanged.next(user);
      this.userStateService.user.next(this.user);
    }
    
  }

  get loginChecked(): boolean {
    return this._loginChecked;
  }

  set loginChecked(loginChecked: boolean) {
    this._loginChecked = loginChecked;
  }

  get hasCredentials(): boolean {
    return !!this.savedToken;
  }

  get savedToken(): string {
    return this.cookieService.get('iguide_auth_token');
  }

  get isLoggedIn(): boolean {
    return this.hasCredentials && !!this._user;
  }

  get isLoggedInAsync(): Observable<boolean> | boolean {
    if (!this.hasCredentials) {
      return false;
    }

    if (!this.loginChecked) {
      return this.checkLogin().pipe(map(user => !!user));
    }

    return this.isLoggedIn;
  }

  /**
   * Checks if a user has a specific role.
   * @param roleName
   * @param user If not specified, will use the local authenticated user.
   */
  hasRole(roleName: string, user?: UserProfile): string {
    if (!user) user = this._user;
    return user.roles.find(role => roleName === role);
  }

  /**
   * Checks if a user has specific roles.
   * @param roles The roles to check if exists
   * @param user If not specified, will use the local authenticated user.
   */
  hasRoles(roles: string[], user?: UserProfile): boolean {
    for (const role of roles) {
      if (this.hasRole(role, user)){
        return true;
      }
    }
   

    return false;
  }

  hasRolesAsync(roles: string[]): boolean | Observable<boolean> {
    if (this.isLoggedIn) 
    return this.hasRoles(roles);

    if (!this.loginChecked) {
      return this.checkLogin().pipe(map(user => this.hasRoles(roles, user)));
    }

    return false;
  }

  constructor(
    private apiService: ApiService,
    private userStateService: UserStateService,
    private cookieService: CookieService,
    private router: Router,
    private modalService: BsModalService
  ) {

  }

  dropAuthModal(){
    const initialState: ModalOptions = {
      initialState: {},
      class: 'modal-dialog-centered modal-xl'
    };
    this.modalRef = this.modalService.show(LoginModalComponent, initialState);
  }

  checkLogin(): Observable<UserProfile> {
    if (!this.hasCredentials) {
      this.loginChecked = true;
      return;
    }

    this.loginChecked = false;
    return this.apiService.getProfile().pipe(
      tap(
        response => {
          this.loginChecked = true;
          this.user = response;
          this.userChanged.next(this.user);
        },
        () => {
          this.loginChecked = true;
        },
      ),
    );
  }

  refreshUser(user){
    this.user = user;
    this.userChanged.next(user);
    this.userStateService.user.next(user);
  }

  forgotPassword(email: object){
    //return this.apiService.forgotPassword(email);
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.apiService.login(email, password).pipe(
      tap(
        result => {
          this.cookieService.put(`iguide_auth_token`, result.token);
          this.user = result.user;
          this.userStateService.user.next(this.user);
          this.modalService.hide();
        },
        error => {
          this.userChanged.error(error);
          console.error(error);
        },
      ),
    );
  }

  registerOrLogin(email: string, password: string, provider: string, registerForm): Observable<any> {
    return this.apiService.registerOrLogin(email, password, provider, registerForm).pipe(
      tap(
        (result: any) => {
          return true;
        },
        error => {
          this.userChanged.error(error);
          console.error(error);
        },
      ),
    );
  }

  /**
   * Signs into using the social authentication credentails provided.
   * @param provider
   * @param authToken
   */
  socialLogin(provider: string, authToken: string): Promise<UserProfile> {
    return this.apiService
      .socialLogin(provider, authToken)
      .toPromise()
      .then(result => {
        this.cookieService.put(`iguide_auth_token`, result.token);
        this.user = result.user;
        this.userStateService.user.next(this.user);
        this.modalService.hide();
        return this.user;
      })
      .catch(error => {
        this.userChanged.error(error);
        return error;
      });
  }

  logout(): Promise<void> {
    this.user = null;
    this.cookieService.remove('iguide_auth_token');
    this.userStateService.user.next(null);
    this.loginChecked = true;
    this.router.navigate(['/']);
    return Promise.resolve(null);
  }
}
