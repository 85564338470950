import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorLogComponent } from './error-log.component';



@NgModule({
  declarations: [
    ErrorLogComponent
  ],
  imports: [
    CommonModule,
  ], exports: [ErrorLogComponent]
})
export class ErrorLogModule { }
