import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PaymentService {
  routePath = 'payment';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  createSession(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/createSession/`);
    return this.httpService.post<any>(url,body);
  }

  createSessionDirectly(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/createSessionDirectly/`);
    return this.httpService.post<any>(url,body);
  }

  validatePayment(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/validatePayment/`);
    return this.httpService.post<any>(url,body);
  }

  getPayments(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getPayments/`);
    return this.httpService.post<any>(url,body);
  }

  book(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/book/`);
    return this.httpService.post<any>(url,body);
  }

  getMyStats(): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getMyStats/`);
    return this.httpService.post<any>(url,{});
  }

  getPaymentLog(): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getPaymentLog/`);
    return this.httpService.post<any>(url,{});
  }

  getAdminPaymentLog(): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminPaymentLog/`);
    return this.httpService.post<any>(url,{});
  }

  getBookings(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getBookings/`);
    return this.httpService.post<any>(url,body);
  }

  getPlatformBookings(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getPlatformBookings/`);
    return this.httpService.post<any>(url,body);
  }

  

  


  

}
