import { LOCALE_ID, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import _ from 'lodash';

@Injectable({
    providedIn: "root"
  })
export class LocaleService {
  activeLocale;
  locales = [{name: 'English', locale: 'en-US', flag: 'gb', shortHand: 'en' }, {name: 'Magyar', locale: 'hu-HU', flag: 'hu', shortHand: 'hu' }]
  activeLocaleObject = {name: 'English', locale: 'en-US', flag: 'gb', shortHand: 'en' };
  constructor(@Inject(LOCALE_ID) public locale: string) {
    this.activeLocale = locale;
    this.activeLocaleObject = _.find(this.locales, (x)=>{return this.activeLocale === x.locale});
    moment.locale(this.activeLocaleObject?.shortHand ? this.activeLocaleObject.shortHand : 'en');
  }

  
}
