import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { AuthService, NotyService, UserService, UserStateService } from '@services';
import { take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {
  @Input() withRedirect = false;
  @Input() withRefresh = true;
  pageSettings = {
    isLogin: true,
    loaders: {
      login: false
    }
  }
  authSubscription: Subscription;
  email: string;
  password: string;
  @ViewChild('focusInput') focusInput: ElementRef;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userStateService: UserStateService,
    private modalService: BsModalService,
    private userService: UserService,
    private notyService: NotyService
  ) {
   

      
      
    
  }

  /**
   * Closes the modal
   */
  closeModal(){
    this.modalService.hide();
  }

  /**
   * Obtains new user password
   */
  onRequestNewPasswordClick(){
    this.userService.forgotPassword({email: this.email}).pipe(take(1)).subscribe((x)=>{
     this.closeModal();
     this.notyService.dropNoty('info',$localize `The new password has been sent to the given email address`);
    });
  }

  /**
   * Performs the new password action
   */
  obtainNewPassword(){
    this.userService.forgotPassword({email: this.email}).pipe(take(1)).subscribe((x)=>{
      
      this.pageSettings.isLogin = true;
      this.notyService.dropNoty('info',$localize `The new password has been sent to the given email`);
    })
  }

  /**
   * Performs the login action
   */
  onLoginClick(): void {
    this.authService.login(this.email, this.password).pipe(take(1)).subscribe((x: any)=>{
      if(this.withRedirect){
        this.router.navigateByUrl('/user/settings');
      } else{
        if(x && x.user && x.user.roles.indexOf('user') === -1){
          this.router.navigateByUrl('/tour-platform');
        } 
      }
     
    });
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.focusInput.nativeElement.focus();
    }, 500);
    
  }

}
