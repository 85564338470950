import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FavoritesService, UserStateService, UtilsService } from '@services';
import * as _ from 'lodash';
@Component({
  selector: 'app-tours-card-list',
  templateUrl: './tours-card-list.component.html',
  styleUrls: ['./tours-card-list.component.scss']
})
export class ToursCardListComponent implements OnInit, OnDestroy {
  @Input() tours;
  @Input() displayMode = 'col-md-4';
  authSubscription;
  
  user;
  constructor(private userStateService: UserStateService, private favouritesService: FavoritesService) { }

  /**
   * Checks if the tour is in the user's favorites
   * @param id - tour id
   * @returns 
   */
  isLiked(id){
    if(this.user){
      return this.favouritesService.isLiked(this.user, id);
    }
  }

  /**
   * Checks for user authentication changes
   */
  initUserChangeDetection(){
    this.authSubscription = this.userStateService.user.subscribe((user)=>{
      if(user){
       this.user = user;
      } else{
        this.user = null;
      }
     })
  }

  /**
   * Default component destruction function
   */
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
    this.initUserChangeDetection();
  }

}
