import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AuthService, FavoritesService, NotyService, TourService } from '@services';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.scss']
})
export class TourCardComponent implements OnInit {
  @Input() tour;
  @Input() type = 'recent';
  @Input() isLiked = false;
  @Input() hasLike = true;
  @Output() refreshEvent = new EventEmitter<any>();
  constructor(private authService: AuthService, private notyService: NotyService, private tourService: TourService, private favoritesService: FavoritesService, @Inject(LOCALE_ID) public locale: string) { }

  /**
   *  Navigates to the tour page
   * @param $event tour object
   */
  async navigateToListing($event) {
    if ($event.target.className.indexOf('bookmark-icon') !== -1) {
      if (this.authService.user) {
        this.isLiked = this.isLiked ? false : true;
        let fav = await this.favoritesService.toggleLike(this.tour._id, this.isLiked);
        
        this.refreshEvent.emit({ changed: true });
      } else {
        this.authService.dropAuthModal();
      }
    } else {
      window.open('/'+this.locale+'/tour/' + this.tour._id, '_blank');
    }

  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
  }

}
