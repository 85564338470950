import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@core/services/config.service';
import { AppService, CityService, NotyService} from '@services';
import { take } from 'rxjs/operators';
import { UserProfile } from '../../../../../shared/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  pageSettings = {
    nearestCitySearch: false
  };
  loaders = {
    getNearbyCity: false
  };
  locales = {
    RecentTours: $localize `Recent Tours`,
    NearbyTours: $localize `Nearby Tours`,
  }
  constructor(public configService: ConfigService, private router: Router, private cityService: CityService, private notyService: NotyService){

  }

  /**
   * Navigates to tours page with the given city
   * @param city Name of the city
   */
  navigateWithCity(city){
    this.router.navigate(['/tours'],{queryParams: {city: city._id}})
  }

  /**
   * Navigates to tour page with category id
   * @param category category id
   */
  navigateWithCategory(category){
    this.router.navigate(['/tours'],{queryParams: {categoryId: category.code}})
  }

  /**
   * Gets the nearest city to the user
   */
  getNearestCity(){
    if (navigator.geolocation) {
      this.loaders.getNearbyCity = true;
      navigator.geolocation.getCurrentPosition((position: any)=>{
        this.cityService.getNearestCity({location: {lat: position.coords.latitude, lng: position.coords.longitude}}).pipe(take(1)).subscribe((x)=>{
         if(x && x.length){
          this.router.navigate(['/tours'],{queryParams: {city: x[0]._id}})
         } else{
          this.notyService.dropNoty('danger', $localize `Cannot find available locations nearby`);
         }
         this.loaders.getNearbyCity = false;
        })
      });
    } 
  }

  /**
   * Gets user's location to initialize nearest city search
   */
  initNearestCitySearch(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any)=>{
        this.pageSettings.nearestCitySearch = true;
      });
    } 
  }

  /**
   * Redirects user to the tours page
   */
  redirectToTours(){
    this.router.navigate(['/tours/'])
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
    this.initNearestCitySearch();
  }
}
