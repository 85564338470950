<div class="p-3 border-bottom">
  <div class="container">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <a href="javascript:void(0)" [routerLink]="['/']"
        class="d-flex align-items-center text-dark text-decoration-none">
        <img src="../../../../assets/img/logo/logo.png" width="130" alt="">
      </a>

      <ul
        class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ms-3 d-none d-lg-flex sfs-text-09">
        <li><a href="javascript:void(0)" routerLink="/" class="nav-link px-2" i18n>Home</a></li>
        <li><a href="javascript:void(0)" routerLink="/tours" class="nav-link px-2" i18n>Tours</a></li>
        <li><a href="javascript:void(0)" class="nav-link px-2" (click)="utilsService.detectPlatformAndNavigateUserToStore()" i18n>App</a></li>
        <li><a href="javascript:void(0)" class="nav-link px-2">
            <ng-container i18n>Invite your friends</ng-container><span class="badge bg-secondary ms-1 fw-bold" i18n>Free
              credits</span>
          </a></li>
      </ul>

      <!-- Logged in user menu -->
      <div class="dropdown ms-auto d-none d-md-block"><a class="nav-link dropdown-toggle fs-sm align-items-center fw-normal" href="#"
          id="langSwitcher" data-bs-toggle="dropdown" role="button" aria-expanded="false"><i
            class="fi-globe mt-n1 me-1 align-middle"></i> {{localeService.activeLocaleObject.name}}</a>
        <ul class="dropdown-menu dropdown-menu-dark my-1" aria-labelledby="langSwitcher">
          <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0)" (click)="navigateToLanguage('hu-HU')"><span class="flag-icon flag-icon-hu me-1"></span>
              <ng-container i18n>Hungarian</ng-container>
            </a></li>
          <li><a class="dropdown-item text-nowrap py-1" href="javascript:void(0)" (click)="navigateToLanguage('en-US')"><span class="flag-icon flag-icon-gb me-1"></span>
              <ng-container i18n>English</ng-container>
            </a></li>
        </ul>
      </div>
      <div class="dropdown text-end d-flex align-items-center ms-auto ms-md-0" *ngIf="user else notLoggedBlock">
        <a href="javascript:void(0)"
          class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle sfs-text-09"
          id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="badge bg-secondary me-2 d-none d-md-block"><img
              src="../../../../assets/img/icons/token.svg" width="20" class="me-1">{{user.availableCredits}}
            <ng-container i18n>credits</ng-container></span> Hello {{user.firstName}} <img
            [src]="user.profilePicture ? user.profilePicture : '../../../../assets/img/general/no-user.png'" alt="mdo"
            width="32" height="32" class="rounded-circle normal ms-2">
        </a>
        <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">
  <li *ngIf="user.roles.indexOf('contributor') !== -1 || user.roles.indexOf('admin') !== -1 || user.roles.indexOf('creator') !== -1"><a class="dropdown-item d-flex align-items-center" href="javascript:void(0)"
            [routerLink]="['/tour-platform']"> <img src="../../../../assets/img/logo/icon.png" width="20" class="me-1"> <ng-container i18n>Tour platform</ng-container></a></li> 
      <li *ngIf="user.roles.indexOf('admin') !== -1"><a class="dropdown-item" href="javascript:void(0)"
              [routerLink]="['/admin/cities']" i18n>Admin settings</a></li> 
              <li (click)="buyCreditsModal()"><a class="dropdown-item" href="javascript:void(0)"i18n>Buy credits</a></li>

          <li *ngFor="let elem of menuElements"><a class="dropdown-item" href="javascript:void(0)"
              [routerLink]="elem.path">{{elem.name}}</a></li>
          <li>
            <li ><a class="dropdown-item" href="javascript:void(0)" (click)="utilsService.detectPlatformAndNavigateUserToStore()" i18n>Get the app</a></li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li><a class="dropdown-item" href="javascript:void(0)" (click)="authService.logout()" i18n>Sign out</a></li>
        </ul>
      </div>
      <!-- End of Logged in user menu -->
      <!-- Not logged in menu -->
      <ng-template #notLoggedBlock>
        <div class="d-flex align-items-center ms-auto ms-md-0">
          <a class="btn btn-sm text-primary" href="javascript:void(0)" (click)="authService.dropAuthModal()"><i
              class="fi-user me-2"></i>
            <ng-container i18n>Sign in</ng-container>
          </a>
          <button class="btn btn-primary d-none d-md-block" [routerLink]="['/register']" i18n>Register now</button>
        </div>
      </ng-template>
      <!-- End of Not logged in menu -->
    </div>
  </div>
</div>