import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioPreviewComponent } from './audio-preview.component';



@NgModule({
  declarations: [
    AudioPreviewComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [AudioPreviewComponent]
})
export class AudioPreviewModule { }
