<section class="container d-flex flex-wrap flex-column flex-sm-row pb-md-5 mb-md-3">
    <a *ngFor="let category of configService.categories"
        class="sfs-category-card icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4"
        href="javascript:void(0)" [class.active]="selectedCategories.indexOf(category.code) !== -1" (click)="selectCategory(category)">
        <div class="icon-box-media bg-faded-primary rounded-circle me-2"><i [class]="category.icon"></i></div>
        <h3 class="icon-box-title fs-sm ps-1 pe-2 mb-0">{{category.name}}</h3>
    </a>

    <a *ngIf="isFactoryFilter"
    class="sfs-category-card icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4"
    href="javascript:void(0)"  [class.active]="isFactoryMade" (click)="isFactoryMade = !isFactoryMade; emitChange()" >
    <div class="icon-box-media bg-faded-primary rounded-circle me-2"><img src="../../../assets/img/cards/verified.svg" width="30" height="30"></div>
    <h3 class="icon-box-title fs-sm ps-1 pe-2 mb-0" i18n>Wingman made</h3>
</a>
  
</section>