import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtaMobileAppComponent } from './cta-mobile-app.component';



@NgModule({
  declarations: [
    CtaMobileAppComponent
  ],
  imports: [
    CommonModule
  ], exports: [CtaMobileAppComponent]
})
export class CtaMobileAppModule { }
