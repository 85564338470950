import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-reviews-block',
  templateUrl: './reviews-block.component.html',
  styleUrls: ['./reviews-block.component.scss']
})
export class ReviewsBlockComponent implements OnInit {
  @Input() reviews = [];
  @Input() tour;
  @Input() reviewObj;
  @Input() type = 'extended';
  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
  
  }
  /**
   * Default after view initialization
   */
  ngAfterViewInit(){
    if(this.type === 'minified'){
      this.reviews = this.reviews.slice(0, this.reviews.length >= 4 ? 4 : this.reviews.length);
    }
    this.reviews = _.map(this.reviews, (review)=>{return _.merge(review, {reviewNumber: Array(review.review).fill(4)})});
    
  }

}
