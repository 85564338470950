<section class="container mb-sm-5 mb-4 pb-lg-4" *ngIf="tours && tours.length">
    <div class="d-flex align-items-center justify-content-between mb-4 pb-2">
        <h2 class="h3 pb-0 mb-0">{{title}}</h2>
        <a class="btn btn-link fw-normal ms-auto p-0"
            href="javascript:void(0)" [routerLink]="['/tours']"><ng-container i18n>View all</ng-container><i class="fi-arrow-long-right ms-2"></i></a>
    </div>
    <div class="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside">
        <div [attr.class]="class">
            <div class="tns-item tns-slide-cloned" *ngFor="let tour of tours" aria-hidden="true" tabindex="-1">
              <app-tour-card [tour]="tour" (refreshEvent)="refreshEvent($event)" [isLiked]="isLiked(tour._id)" [type]="type"></app-tour-card>  
              </div>
          </div>
    </div>
</section>