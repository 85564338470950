import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourListComponent } from './tour-list.component';
import { NoDataBlockModule } from '../no-data-block/no-data-block.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TourListComponent
  ],
  imports: [
    CommonModule,
    NoDataBlockModule,
    FormsModule
  ], exports: [TourListComponent]
})
export class TourListModule { }
