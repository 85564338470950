import { Component } from '@angular/core';
import { ApiService, UtilsService } from '@services';
import { take } from 'rxjs/operators';
import {LocaleService} from '../../services/misc/locale.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  config;
  constructor(public localeService: LocaleService, private apiService: ApiService, public utilsService: UtilsService){
    this.getConfig();
  }

  getConfig(){
    this.apiService.getConfig().pipe(take(1)).subscribe((config)=>{
      this.config = config;
    })
  }
}
