<div class="modal-header no-border">
  <h4 class="modal-title pull-left" i18n>Book tour</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <section class="container mt-n3 mt-md-0">
    <div class="row">
      <div class="col-md-12 d-flex align-items-center">
        <div class="w-100">
          <div class="sfs-checkout-container p-3">
            <tabset #staticTabs type="pills" [justified]="true" customClass="nav-tabs nav-fill justify-content-center">

              <!-- TAB -->
              <tab>
                <ng-template tabHeading (click)="loadTab(0)">
                  <i class="fi-info-circle me-1"></i><span i18n>Summary</span>
                </ng-template>
  
                <div class="row mt-3" *ngIf="tour">
                  <div class="col-12">
                    <label class="sfs-text-09" i18n>Number of participants</label>
                    <div class="input-group mt-2">
                      <input type="number" class="form-control" min="1" [(ngModel)]="bookObj.noOfParticipants"
                        placeholder="Number of participants" i18n-placeholder>
                      <span class="input-group-text" i18n>person</span>
                    </div>
                    <label class="sfs-text-09 mt-3" i18n>Tour language</label>
                    <ng-select [searchable]="false" [clearable]="false" class="mt-2" placeholder="Languages" i18n-placeholder [(ngModel)]="bookObj.language">
                      <ng-option *ngFor="let language of tour.languages; trackBy:identify" [value]="language.code">
                        <span class="flag-icon flag-icon-{{language.code === 'en' ? 'gb' : language.code}} me-1"></span>{{language.name}}
                      </ng-option>
                    </ng-select>
                    <label class="sfs-text-09 mt-3" i18n>Invite code (optional)</label>
                      <input type="text" class="form-control" maxlength="6" [(ngModel)]="bookObj.inviteCode"
                        placeholder="Your invite code" i18n-placeholder>
                  </div>
                 
                </div>
              </tab>
              <!-- END OF TAB -->
              <!-- TAB -->
              <tab *ngIf="bookObj.noOfParticipants > 1">
                <ng-template tabHeading (click)="loadTab(1)">
                  <i class="fi-info-circle me-1"></i><span i18n>Participants</span>
                </ng-template>
                <div class="row mt-3">
                  <div class="col-12">
                    <p class="text-muted sfs-text-09 text-center"><ng-container i18n>Share the tour with</ng-container>&nbsp;{{bookObj.noOfParticipants}}<ng-container i18n> of your
                      friends by typing the email addresses. Don't worry, you can skip this step for now.</ng-container></p>
                    <form class="form-group">
                      <div class="input-group">
                        <span class="input-group-text text-muted">
                          <i class="fi-mail"></i>
                        </span>
                        <input type="text" class="form-control" name="email" [(ngModel)]="email"
                          placeholder="Email address" i18n-placeholder>
                      </div>
                      <button type="button" (click)="addUser()"
                        [disabled]="!isValidEmail() || bookObj.noOfParticipants===bookObj.participants.length || !email"
                        class="btn btn-translucent-primary" i18n>Add</button>
                    </form>
                    <div class="row mt-3" *ngIf="bookObj.participants && bookObj.participants.length > 0 else noDataBlock">
                      <div class="col-12">
                       
                        <table class="basic-table">
                          <thead>
                            <tr>
                              <th scope="col sfs-text-08 fw-bold">Email</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
  
                          <tbody>
                            <tr *ngFor="let user of bookObj.participants">
                              <td class="sfs-text-09">
                                {{user.email}}
                              </td>
                              <td>
                                <button class="btn btn-sm btn-outline-danger btn-icon" (click)="removeUser(user)"
                                  type="button"><i class="fi-trash"></i></button>
                              </td>
  
                            </tr>
  
                          </tbody>
                        </table>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
            <div class="col-12 mt-3">
              <button class="btn btn-primary btn-block w-100 scale-up" [disabled]="!bookObj.noOfParticipants || (bookObj.noOfParticipants*tour.credit) > user.availableCredits || pageSettings.loaders.book" (click)="next()">
                <span *ngIf="!pageSettings.loaders.book else loaderBlock">
                  {{bookObj.noOfParticipants <= 1 || (bookObj.noOfParticipants > 1 && pageSettings.activeTabIndex === 1 ) ? (locales.Checkout + ' (' + (bookObj.noOfParticipants*tour.credit) + ' ' +  locales.Credits + ')') : locales.Next}}
                </span>
                <ng-template #loaderBlock>
                  <div class="spinner-border" role="status">
                  </div>
                </ng-template>
              </button>
            </div>
          </div>
        

        </div>

      </div>
    </div>
  </section>
</div>

<ng-template #noDataBlock>
  <app-no-data-block [icon]="'fi-users'" [title]="locales.NoMates" [subtitle]="locales.SetEmail">
  </app-no-data-block>
</ng-template>