<div class="container px-0 py-5">
  <div class="row mx-0 align-items-center">
    <div class="col-md-6 border-end-md p-4 p-sm-5">
      <h2 class="h3 mb-4 mb-sm-5"><ng-container i18n>Join Wingman.</ng-container><br><ng-container i18n>Explore cities around the world.</ng-container></h2>
      <ul class="list-unstyled mb-4 mb-sm-5">
        <li class="d-flex mb-2"><i class="fi-check-circle text-primary mt-1 me-2"></i><span><ng-container i18n>Create unique, local tours</ng-container></span></li>
        <li class="d-flex mb-2"><i class="fi-check-circle text-primary mt-1 me-2"></i><span><ng-container i18n>Explore unique, local tours</ng-container></span></li>
        <li class="d-flex mb-0"><i class="fi-check-circle text-primary mt-1 me-2"></i><span><ng-container i18n>Leave reviews</ng-container></span></li>
      </ul>
      <img class="d-block mx-auto" src="../../../../assets/img/logo/icon.png" width="250">

    </div>
    <div class="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
      <!-- Social login -->
    <!--   <app-social-login-button [type]="'register'"></app-social-login-button> -->
      <!-- Reg form -->
    <!--   <div class="d-flex align-items-center py-3 mb-3">
        <hr class="w-100">
        <div class="px-3" i18n>Or</div>
        <hr class="w-100">
      </div> -->
      <form [formGroup]="userProfileForm" class="col-12 form" >
        <div class="mb-4">
          <label class="form-label" i18n>First name</label>
          <input class="form-control" type="text" formControlName="firstName" id="signup-firstName" i18n-placeholder  placeholder="Enter your first name" >
        </div>
        <div class="mb-4">
          <label class="form-label" i18n>Last name</label>
          <input class="form-control" type="text" formControlName="lastName" id="signup-lastName" i18n-placeholder  placeholder="Enter your last name" >
        </div>
        <div class="mb-4">
          <label class="form-label" for="signup-email" i18n>Email address</label>
          <input class="form-control" type="email" formControlName="email" id="signup-email" i18n-placeholder placeholder="Enter your email">
        </div>
        <div class="mb-4">
          <label class="form-label" for="signup-password"><ng-container i18n>Password</ng-container> <span class='fs-sm text-muted' i18n>min. 6 char</span></label>
          <div class="password-toggle">
            <input class="form-control" type="password" formControlName="password" i18n-placeholder placeholder="Password" id="signup-password" >
          </div>
        </div>
        <button class="btn btn-primary btn-lg rounded-pill w-100"  [disabled]="!userProfileForm.valid || !optIn || pageSettings.loaders.register"
        (click)="onRegisterClick()" type="submit" i18n>Sign up</button>
      </form>
      <div class="form-check mb-4 mt-3">
        <input class="form-check-input" type="checkbox" name="Optin" id="agree-to-terms" [(ngModel)]="optIn">
        <label class="form-check-label" for="agree-to-terms"> <ng-container i18n> By joining, I agree to the </ng-container> <a [href]="configService.termsOfServiceURL ? configService.termsOfServiceURL : ''" target="_blank" i18n>Terms of use </a> <ng-container i18n>and</ng-container>&nbsp;<a [href]="configService.privacyPolicyURL ? configService.privacyPolicyURL : ''" i18n>Privacy policy</a></label>
      </div>
    </div>
  </div>
</div>


<!-- <div class="container">
  <div class="card">
    <div class="card-header">Register a new user</div>
    <div class="card-body">
      <form #form="ngForm" class="col-12 form" [appFormValidator]="userProfile" [appFormValidatorForce]="true"
        (appFormValidatorIsFormValidChange)="onFormValidChange($event)">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" class="form-control" name="email" [(ngModel)]="userProfile.email" id="email"
                  aria-describedby="helpId" placeholder="mail@gmail.com">
              </div>

              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" name="password" [(ngModel)]="userProfile.password"
                  id="password" aria-describedby="helpId" placeholder="Password">
              </div>

            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName">First name:</label>
                <input type="text" class="form-control" name="firstName" [(ngModel)]="userProfile.firstName"
                  id="firstName" aria-describedby="helpId" placeholder="">
              </div>

              <div class="form-group">
                <label for="lastName">Last name:</label>
                <input type="text" class="form-control" name="lastName" [(ngModel)]="userProfile.lastName" id="lastName"
                  aria-describedby="helpId" placeholder="">
              </div>
            </div>

            <div class="col-12">
              <button type="submit" [disabled]="!isFormValid" class="btn btn-primary mt-2"
                (click)="onRegisterClick()">Register</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div> -->