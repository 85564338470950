import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookComponent } from './book.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NoDataBlockModule } from '../../no-data-block/no-data-block.module';
import { LoaderModule } from '../../loader/loader.module';



@NgModule({
  declarations: [
    BookComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TabsModule,
    LoaderModule,
    NoDataBlockModule
  ], exports: [BookComponent]
})
export class BookModule { }
