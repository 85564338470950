import { CommonModule } from '@angular/common';
import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoDataBlockComponent } from './no-data-block.component'

@NgModule({
  declarations: [
    NoDataBlockComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  providers: [],
  exports: [NoDataBlockComponent]
})
export class NoDataBlockModule {}
