import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToursCardListComponent } from './tours-card-list.component';
import { TourCardModule } from '../tour-card/tour-card.module';



@NgModule({
  declarations: [
    ToursCardListComponent
  ],
  imports: [
    CommonModule,
    TourCardModule
  ], 
  exports: [ToursCardListComponent]
})
export class ToursCardListModule { }
