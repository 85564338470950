import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, FavoritesService, NotyService, ReviewService, TourService, UserStateService } from '@services';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { BookComponent } from 'src/app/modules/payment/book/book.component';
import { CheckoutComponent } from 'src/app/modules/payment/checkout/checkout.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfigService } from '@core/services/config.service';
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"
import lightGallery from 'lightgallery';

@Component({
  selector: 'app-detailed-tour',
  templateUrl: './detailed-tour.component.html',
  styleUrls: ['./detailed-tour.component.scss']
})
export class DetailedTourComponent implements OnInit {
  settings = {
    counter: true,
    addClass: 'detailedSlider',
    plugins: []
  };  
  slider;
  gallery;
  isLiked = false;
  modalRef;
  tourId;
  tour;
  user;
  reviews = [];
  tourDescription;
  authSubscription;
  navigationUrl;
  reviewObj = {
    analytics: [0,0,0,0,0],
    reviewStars: []
  };
  pageSettings = {
    descriptionToggled: false,
    detailsView: true,
    canLoadMaps: false
  };
  locales = {
    outdoor: $localize `Outdoor`,
    indoor: $localize `Indoor`
  }
  
  constructor(private route: ActivatedRoute, private router: Router, private tourService: TourService, public userStateService: UserStateService, private modalService: BsModalService, private authService: AuthService, private favoritesService: FavoritesService, public configService: ConfigService, private reviewService: ReviewService, private notyService: NotyService, @Inject(LOCALE_ID) public localePrefix: string) { }

  navigateToMain(){
    //this.router.navigate(['/404']);
  }

  /**
   * Switches to the selected locale
   * @param locale locale
   */
  navigateToLocalePage(locale){
    window.open('/'+this.localePrefix+'/tour/'+this.tourId+'/'+locale, '_blank');
  }

  /**
   * Opens image gallery with the selected image
   * @param index index of the image
   */
  openImage(index){
    console.log(this.gallery)
    this.gallery.openGallery(index)  
  }
  /**
   * Initializes the image carousel
   */
  drawCarousel(){
    setTimeout(()=>{
      if(document.querySelector('.detailedSlider')){
        this.slider = tns({
          container: '.detailedSlider',
          slideBy: 'page',
          autoplay: false,
          nav: true,
          navPosition: "bottom",
          autoplayButtonOutput: false,
          controls: false,
          responsive: {
            576: {
              items: 1
            },
            768: {
              items: 2
            },
            992: {
              items: 2
            }
          },
        });
      }
      
    },10)
  }

  /**
   * Initializes the gallery component
   */
  initGallery(){
    setTimeout(()=>{
      this.gallery = lightGallery(document.getElementById('detailedImageContainer'), {
        dynamic: true,
        dynamicEl: _.filter(_.map(this.tour.media, (media)=>{
          return {
            src: media.url,
            thumb: media.url,
            subHtml: '<h4></h4>',
          }
        }), (m: any)=>{
          return m.url;
        })
      });
      console.log(this.gallery)
    }, 10)
  }

/**
 * Sets the state of the page
 * @param state view state
 */
  setActiveTab(state){
    this.pageSettings.detailsView = state;
    if(!state){
      this.pageSettings.canLoadMaps = false;
    } else{
      this.pageSettings.canLoadMaps = true;
    }
  }


  arrayFromNumber(number){
    if(number){
      return Array(number).fill(0);
    } else{
      return [];
    }
  }

  sightSelected($event){
    this.tour.sights = _.map(this.tour.sights, (x)=>{ return _.merge(x, {selected: false})});
    _.find(this.tour.sights, (x)=>{return x._id === $event.id}).selected = true;
  }


  /**
   * Opens a modal with the selected template
   * @param template template to be shown
   */
  openModal(template) {
    this.modalRef = this.modalService.show(template);
  }

  /**
   * Gets authenticated user details
   */
  getUserDetails(){
    this.authSubscription = this.userStateService.user.subscribe((user)=>{
     if(user){
      this.user = user;
    }});
  }

  /**
   * Shows all reviews for the tour in the modal
   * @param template template to be shown
   */
  showAllReviews(template){
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  /**
   * Generates the Google Maps URL for the tour
   */
  getNavigationUrl(){
    let url = 'https://www.google.com/maps/dir/';
    _.forEach(this.tour.sights, (s)=>{
      url += s.coordinates.coordinates[1] + ',' + s.coordinates.coordinates[0] + '/'
    })
    this.navigationUrl = url;
  }

  formatBasedOnType(n){
    if(n % 1 === 0){
      return n;
    } else{
      return n.toFixed(1);
    }
}

  /**
   * Gets the tour details
   * @param locale locale
   */
  getTourDetails(locale){
    this.tourService.getTourForDetails({id: this.tourId, analytics: true, locale}).pipe(take(1)).subscribe((tour)=>{
      if(!tour){
        this.navigateToMain();
      } else{
      
        tour.media = _.filter(tour.media, (m)=>{
          return m.url;
        });
        if(tour.media.length < 2){
         _.forEach(tour.sights, (s)=>{
            if(s.media.length > 0){
              tour.media.push(s.media[0]);
            }
         })
        }
        tour.durationFormatted = tour.distance ? this.formatBasedOnType((tour.duration/60)) : 0,
        this.tour = tour;
        this.tour.languages = _.map(this.tour.languages, (language)=>{ 
          let lang = _.find(this.configService.availableLanguages, (x)=>{return x.code  === language}) ;
          if(lang && lang.name){
            return lang;
          } else{
            return {
              code: language
            }
          }
        })
       
        //this.initGallery();
        this.drawCarousel();
        this.getNavigationUrl();
        this.toggleTourDescription();
        this.isLikedTour();
        this.getReviews();
        
        setTimeout(()=>{
          this.pageSettings.canLoadMaps = true;
        },1000)
      }
    })
  }

  /**
   * Navigates to tour search with the selected city
   * @param city city
   */
  navigateWithCity(city){
    this.router.navigate(['/tours'],{queryParams: {city: city._id}})
  }

  /**
   * Gets the reviews for the tour
   */
  getReviews(){
    this.reviewService.getReviews({id: this.tourId}).pipe(take(1)).subscribe((reviews)=>{
    
     this.reviews = _.map(reviews, (review)=>{
       return _.merge(review, {createdFormatted: moment(review.created).format('YYYY/MM/DD')})
     });

     _.forEach(this.reviews, (r)=>{
       this.reviewObj.analytics[r.review-1]++;
     })

     _.forEach(this.reviewObj.analytics, (r, i)=>{
      this.reviewObj.analytics[i] = Math.round((r/this.reviews.length)*100);
     })

     
     if(this.tour.rating){
      this.reviewObj.reviewStars = this.arrayFromNumber(Math.floor(this.tour.rating));
     }


      
      
    })
  }

  /**
   * Toggles the like state of the tour
   */
  async toggleLike(){
    if(this.user){
      this.isLiked = this.isLiked ? false : true;
      await this.favoritesService.toggleLike(this.tour._id, this.isLiked);
    } else{
      this.authService.dropAuthModal();
    }
  }

  /**
   * Checks if the user has liked the tour or now
   */
  isLikedTour(){
    if(this.user){
      this.isLiked = this.favoritesService.isLiked(this.user, this.tour._id);
    }
  }

  /**
   * Toggles the description of the tour
   */
  toggleTourDescription(){
    this.pageSettings.descriptionToggled = !this.pageSettings.descriptionToggled;
    let description = this.tour.tourDescription.value;
    if(this.pageSettings.descriptionToggled){
      this.tourDescription = description.length > 250 ? description.substr(0,250) + '...' : description;
    } else{
      this.tourDescription = this.tour.tourDescription.value;
    }
   
  }

  /**
   * Opens credit checkout modal
   */
  buyCreditsModal(){
      this.modalRef = this.modalService.show(CheckoutComponent, { class: 'modal-xl modal-dialog-centered'});
  }
  /**
   * Opens booking modal
   */
  appBookModal(){
    if(this.user){
      if(this.user.availableCredits >= this.tour.credit){
          this.modalRef = this.modalService.show(BookComponent, { class: 'modal modal-dialog-centered', initialState: {tour: this.tour, user: this.user}});
          this.modalService.onHide
            .pipe(take(1))
            .subscribe((reason) => {
                if(reason === 'refresh'){
                  this.notyService.dropNoty('info',$localize `Congrats! You purchase was successfull!`)
                  this.router.navigate(['/user/myTours']);
                } else if(reason === 'cancelBook'){
                  this.notyService.dropNoty('warning',$localize `You already booked this trip.`)
                }
            });
   
      
      } else{
        this.buyCreditsModal();
      }
   
    } else{
      this.authService.dropAuthModal();
    }
    
  }
/**
 * Default component initialization function
 */
  ngOnInit(): void {
    this.tourId = this.route.snapshot.paramMap.get('id');
    let locale = this.route.snapshot.paramMap.get('locale');

    
    if(!this.tourId){
        this.navigateToMain();
    } else{
      this.getUserDetails();
      this.getTourDetails(locale);
    }
  }

  /**
   * Default component destroy function
   */
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

}
