import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AdminService, ApiService, AppService, AuthGuardService, AuthService, RequestsService, UserService, NotificationService, SightService, UtilsService, TourService, CityService, PaymentService, NotyService, FavoritesService, ReviewService, IssueService, ClickupService, InfoService, SelectionsService, CommentsService, LogsService, ErrorService, AIService } from '@services';

import { SharedModule } from '../shared/shared.module';
import { SocialLoginModule } from '../social-login/social-login.module';
import { AppHttpInterceptor } from './app-http.interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    
    CookieModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    RouterModule,
    SharedModule
  ],
  declarations: [HeaderComponent, FooterComponent],
  providers: [
    AIService,
    ApiService,
    AuthService,
    AdminService,
    UserService,
    AuthGuardService,
    AppService,
    NotificationService,
    SightService,
    TourService,
    CityService,
    PaymentService,
    UtilsService,
    NotyService,
    FavoritesService,
    ReviewService,
    IssueService,
    ClickupService,
    InfoService,
    SelectionsService,
    CommentsService,
    LogsService,
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    RequestsService,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ToastrModule
  ],
})
export class CoreModule {}
