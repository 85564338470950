import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TourService } from '@services';
import { take } from 'rxjs/operators';
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"

@Component({
  selector: 'app-similar-tours',
  templateUrl: './similar-tours.component.html',
  styleUrls: ['./similar-tours.component.scss']
})
export class SimilarToursComponent implements OnInit {
  @Input() id;
  tours = [];
  @Input() classExt;
  @Input() class;
  constructor(private http: HttpClient, private tourService: TourService) { }
  
  /**
   * Get the similar tours from the server
   */
  getSimilarTours(){
    this.tourService.getSimilarTours({id: this.id}).pipe(take(1)).subscribe((tours)=>{
      this.tours = tours;
      
      this.drawCarousel();
    })
  }

  /**
   * Draws tour carousel
   */
  drawCarousel(){
    setTimeout(()=>{
      if(document.querySelector(this.classExt)){
        let slider = tns({
          container: this.classExt,
          items: 2,
          slideBy: 'page',
          gutter: 10,
          autoplay: false,
          autoplayButtonOutput: false,
          controls: false
        });
      }
    },10)
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
    this.getSimilarTours();
  }

}
