
<div class="row mb-md-5 mb-4" *ngIf="tour && tour._id">
  
  <!-- Rating breakdown-->
  <div class="col-sm-8 order-sm-1 order-2">
    <div class="d-flex align-items-center mb-2 fs-sm"
      *ngFor="let analytics of reviewObj.analytics; let i = index">
      <div class="text-nowrap">{{i+1}}<i class="fi-star mt-n1 ms-1 align-middle opacity-70"></i></div>
      <div class="progress w-100 mx-3">
        <div class="progress-bar bg-warning" role="progressbar" style="width: {{analytics}}%"
          aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
      </div><span style="min-width: 48px;">{{analytics}}%</span>
    </div>

  </div>
  <!-- Score-->
  <div class="col-sm-4 order-sm-2 order-1 mb-sm-0 mb-3 text-center">
    <h5 class="display-4 mb-2">{{tour.rating.toFixed(1)}}</h5>
    <div><span class="star-rating"><i class="star-rating-icon fi-star-filled active"
          *ngFor="let star of reviewObj.reviewStars"></i></span>
    </div><span class="fs-sm">{{reviews.length}} <ng-container i18n> reviews</ng-container></span>
  </div>
</div>

<div class="row">
  <div class="col-12" [class]="reviews.length === 1 ? 'col-md-12' : 'col-md-6'" *ngFor="let review of reviews;">
    <div class="mb-4 pb-4 border-bottom">
      <div class="d-flex justify-content-between mb-3">
        <div class="d-flex align-items-center pe-2"><img class="rounded-circle me-1"
            [src]="review.user.profilePicture ? review.user.profilePicture : '../../../assets/img/general/no-user.png'"
            width="48" alt="Avatar">
          <div class="ps-2">
            <h6 class="fs-base mb-0">{{review.user.firstName}} {{review.user.lastName}}</h6><span
              class="star-rating"  *ngFor="let start of review.reviewNumber">
              <i class="star-rating-icon fi-star-filled active"></i></span>
          </div>
        </div><span class="text-muted fs-sm">{{review.createdFormatted}}</span>
      </div>
      <p class="mb-0">{{review.comment}}</p>
    </div>
  </div>
</div>
<!-- Review-->


