import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ClickupService {
  routePath = 'clickup';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  getTask(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTask/`);
    return this.httpService.post<any>(url,body);
  }

  getTasks(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTasks/`);
    return this.httpService.post<any>(url,body);
  }

  getFolders(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getFolders/`);
    return this.httpService.post<any>(url,body);
  }

  getLists(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getLists/`);
    return this.httpService.post<any>(url, body);
  }

  getSynchTasksNumber(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getSynchTasksNumber/`);
    return this.httpService.post<any>(url, body);
  }

  synchTasks(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/synchTasks/`);
    return this.httpService.post<any>(url, body);
  }

  synchPriorities(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/syncPriorities/`);
    return this.httpService.post<any>(url, body);
  }

  exportXLIFF(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/exportXLIFF/`);
    return this.httpService.post<any>(url, body, { responseType:'text' as 'json'
    });
  }

  getTranslationOfForSight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTranslationOfForSight/`);
    return this.httpService.post<any>(url, body);
  }

  XLIFFtoJSON(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/XLIFFtoJSON/`);
    return this.httpService.post<any>(url, body);
  }

  synchTranslation(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/synchTranslation/`);
    return this.httpService.post<any>(url, body);
  }

  cloneTourForTranslation(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/cloneTourForTranslation/`);
    return this.httpService.post<any>(url, body);
  }

  batchTranslation(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/batchTranslation/`);
    return this.httpService.post<any>(url, body);
  }

  

  

  

  


}
