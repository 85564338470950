import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfile } from '../../../../../../shared/models';
import { LoginResponse } from '../../../../../../shared/models/login-response';
import { environment } from '../../../../environments/environment';

@Injectable()
export class SightService {
  routePath = 'sight';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  createSight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/createSight/`);
    return this.httpService.post<any>(url,{body});
  }

  updateSight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/updateSight/`);
    return this.httpService.post<any>(url,{body});
  }

  modifySight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/modifySight/`);
    return this.httpService.post<any>(url,{body});
  }

  removeSight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/removeSight/`);
    return this.httpService.post<any>(url,{body});
  }

  getSights(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getSights/`);
    return this.httpService.post<any>(url,body);
  }

  getSight(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getSight/`);
    return this.httpService.post<any>(url,body);
  }

  getSightTags(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getSightTags/`);
    return this.httpService.post<any>(url,body);
  }

  getAdminSightsV2(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminSightsV2/`);
    return this.httpService.post<any>(url,body);
  }

  getAdminSightV2(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminSightV2/`);
    return this.httpService.post<any>(url,body);
  }

  changeAssignee(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/changeAssignee/`);
    return this.httpService.post<any>(url,body);
  }

  changeFactoryStatus(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/changeFactoryStatus/`);
    return this.httpService.post<any>(url,body);
  }

  
  

}
