<div class="sightseeingMap" [id]="mapId">
    <!-- Sights aside -->
    <div id="sights_aside" class="shadow p-3">
        <h6>{{tour.sights[activeIndex].tourName.value}}</h6>
        <p>{{tour.sights[activeIndex].tourDescription}}</p>
        <hr>
        <div class="d-flex w-100 mt-3">
            <button class="btn btn-primary btn-sm active me-1 w-100" (click)="prevSight()"><i class="fi-arrow-long-left"></i> <ng-container i18n> Prev</ng-container></button>
            <button class="btn btn-primary btn-sm active w-100" (click)="nextSight()"> <ng-container i18n>Next</ng-container><i class="fi-arrow-long-right ms-1"></i></button>
        </div>
    
    </div>
</div>

