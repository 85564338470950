<!-- Hero-->
<div class="page-wrapper">
    <!-- Hero -->
    <section class="container py-5 px-5 mt-5 mb-lg-3">
        <div class="row align-items-center mt-md-2">
            <div class="col-lg-7 order-lg-2 mb-lg-0 mb-4 pb-2 pb-lg-0"><img class="d-block mx-auto"
                    src="../../../assets/img/home/hero-img.jpg" width="746" alt="Hero image"></div>
            <div class="col-lg-5 order-lg-1 pe-lg-0">
                <h1 class="display-5 mb-4 me-lg-n5 text-lg-start text-center mb-4" i18n>Start exploring <span
                        class="dropdown d-inline-block">
                        <a class="dropdown-toggle text-decoration-none" href="#" role="button" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Cities</a>
                        <span class="dropdown-menu dropdown-menu-end my-1" *ngIf="configService.cities">
                            <a class="dropdown-item fs-base fw-bold" href="javascript:void(0)"
                                (click)="navigateWithCity(city)"
                                *ngFor="let city of configService.cities">{{city.name}}</a></span></span></h1>
                <p class="text-lg-start text-center mb-4 mb-lg-5 fs-lg" i18n>Find great places to stay, eat, shop, or visit
                    from our partners and local experts.</p>
              
                <!-- Search form-->
                <div class="me-lg-n5">
                    <form class="form-group d-block d-md-flex position-relative rounded-md-pill me-lg-n5">

                        <div class="d-sm-flex w-100">
                            <div class="dropdown w-100 mb-sm-0 mb-3 border-end-sm" data-bs-toggle="select">
                                <button
                                    class="btn btn-link btn-lg dropdown-toggle ps-2 ps-sm-3 w-100 text-center text-md-start"
                                    type="button" data-bs-toggle="dropdown"><i class="fi-list me-2"></i><span
                                        class="dropdown-toggle-label" i18n>All categories</span></button>
                                <input type="hidden">
                                <ul class="dropdown-menu">
                                    <li *ngFor="let category of configService.categories"><a class="dropdown-item"
                                            href="javascript:void(0)" (click)="navigateWithCategory(category)"><i
                                                class="fs-lg opacity-60 me-2" [class]="category.icon"></i><span
                                                class="dropdown-item-label">{{category.name}}</span></a></li>

                                </ul>
                            </div>
                            <div class="dropdown w-100 mb-sm-0 mb-3" data-bs-toggle="select">
                                <button
                                    class="btn btn-link btn-lg dropdown-toggle ps-2 ps-sm-3 w-100 text-center text-md-start"
                                    type="button" data-bs-toggle="dropdown"><i class="fi-map-pin me-2"></i><span
                                        class="dropdown-toggle-label" i18n>Location</span></button>
                                <input type="hidden">
                                <ul class="dropdown-menu">
                                    <li (click)="navigateWithCity(city)" *ngFor="let city of configService.cities"><a
                                            class="dropdown-item" href="javascript:void(0)"><span
                                                class="dropdown-item-label">{{city.name}}</span></a></li>

                                </ul>
                            </div>
                            <button class="btn btn-primary btn-lg rounded-pill w-100 w-md-auto ms-sm-3" type="button"
                                (click)="redirectToTours()" i18n>Search</button>
                        </div>

                    </form>
                    <div class="d-flex mt-2 ps-3 w-100 text-center" *ngIf="pageSettings.nearestCitySearch">
                    
                        <small><a href="javascript:void(0)" *ngIf="!loaders.getNearbyCity else loaderBlock" (click)="getNearestCity()" class="sfs-no-link"><i class="fi-map-pin me-1"></i><ng-container i18n>Find available tours nearby</ng-container></a></small>
                        <ng-template #loaderBlock>
                            <div class="spinner-border small" role="status">
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container py-md-5 px-md-5 px-3 mt-md-5 mt-0 mb-lg-3">
        <!-- Categories -->
        <app-landing-categories [isFactoryFilter]="false"></app-landing-categories>
        <!-- Landing cities -->
        <app-landing-cities [class]="'cities'" [supportClass]="'mt-md-0 mt-5'" [classExt]="'.cities'"></app-landing-cities>
        <!-- Search list -->
        <app-search-list [type]="'recent'" [class]="'recent'" [classExt]="'.recent'" [title]="locales.RecentTours">
        </app-search-list>
        <!-- Nearby list -->
        <app-search-list [type]="'nearby'" [class]="'nearby'" [classExt]="'.nearby'" [title]="locales.NearbyTours">
        </app-search-list>
       
        <!-- App CTA Knowledgebase -->
        <app-cta-knowledgebase></app-cta-knowledgebase>
    </section>

</div>