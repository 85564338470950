import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfile } from '../../../../../../shared/models';
import { LoginResponse } from '../../../../../../shared/models/login-response';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TourService {
  routePath = 'tour';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  createTour(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/createTour/`);
    return this.httpService.post<any>(url,body);
  }

  modifyTour(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/modifyTour/`);
    return this.httpService.post<any>(url,{body});
  }

  getTour(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTour/`);
    return this.httpService.post<any>(url,body);
  }

  getTourForAdmin(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTourForAdmin/`);
    return this.httpService.post<any>(url,body);
  }

  

  getTourForDetails(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTourForDetails/`);
    return this.httpService.post<any>(url,body);
  }

  getTourRaw(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTourRaw/`);
    return this.httpService.post<any>(url,body);
  }

  getRecent(): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getRecentTours/`);
    return this.httpService.post<any>(url, {});
  }

  getNearby(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getNearbyTours/`);
    return this.httpService.post<any>(url,body);
  }

  removeTour(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/removeTour/`);
    return this.httpService.post<any>(url,{body});
  }

  getTours(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getTours/`);
    return this.httpService.post<any>(url,body);
  }

  getSimilarTours(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getSimilarTours/`);
    return this.httpService.post<any>(url,body);
  }

  getPurchasedTours(body: object): Observable<any> {
    const url = this.getApiEndpoint(`${this.routePath}/getPurchasedTours/`);
    return this.httpService.post<any>(url,{body});
  }

  getFeaturedTour(body){
    const url = this.getApiEndpoint(`${this.routePath}/getFeaturedTour/`);
    return this.httpService.post<any>(url,body);
  }

  filterTours(body: object){
    const url = this.getApiEndpoint(`${this.routePath}/filterTours/`);
    return this.httpService.post<any>(url,body);
  }

  getLanguages(body: object){
    const url = this.getApiEndpoint(`${this.routePath}/getLanguages/`);
    return this.httpService.post<any>(url,body);
  }

  like(body: object){
    const url = this.getApiEndpoint(`${this.routePath}/likeTour/`);
    return this.httpService.post<any>(url,body);
  }

  getFavourites(){
    const url = this.getApiEndpoint(`${this.routePath}/getFavourites/`);
    return this.httpService.post<any>(url,{});
  }

  getTags(){
    const url = this.getApiEndpoint(`${this.routePath}/getTags/`);
    return this.httpService.post<any>(url,{});
  }

  getToursMeta(){
    const url = this.getApiEndpoint(`${this.routePath}/getToursMeta/`);
    return this.httpService.post<any>(url,{});
  }

  getToursForValidation(body: object){
    const url = this.getApiEndpoint(`${this.routePath}/getToursForValidation/`);
    return this.httpService.post<any>(url, body);
  }

  validate(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/validate/`);
    return this.httpService.post<any>(url,body);
  }
  decline(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/decline/`);
    return this.httpService.post<any>(url,body);
  }
  submitForValidation(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/submitForValidation/`);
    return this.httpService.post<any>(url,body);
  }
  getAdminTours(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminTours/`);
    return this.httpService.post<any>(url,body);
  }
  getAdminToursV2(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminToursV2/`);
    return this.httpService.post<any>(url,body);
  }
  changeAssignee(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/changeAssignee/`);
    return this.httpService.post<any>(url,body);
  }

  toggleState(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/toggleState/`);
    return this.httpService.post<any>(url,body);
  }
  getAdminToursMeta(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminToursMeta/`);
    return this.httpService.post<any>(url,body);
  }
  getToursByCity(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getToursByCity/`);
    return this.httpService.post<any>(url,body);
  }
  getActiveToursByCity(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getActiveToursByCity/`);
    return this.httpService.post<any>(url,body);
  }
  getTier(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getTier/`);
    return this.httpService.post<any>(url,body);
  }
  changeFactoryStatus(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/changeFactoryStatus/`);
    return this.httpService.post<any>(url,body);
  }
  getToursV2(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getToursV2/`);
    return this.httpService.post<any>(url,body);
  }
  getNumberOfToursInValidation(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getNumberOfToursInValidation/`);
    return this.httpService.post<any>(url,body);
  }
  getStatusesMeta(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/getStatusesMeta/`);
    return this.httpService.post<any>(url,body);
  }
  makeGroupActions(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/makeGroupActions/`);
    return this.httpService.post<any>(url,body);
  }
  test(body: object) {
    const url = this.getApiEndpoint(`${this.routePath}/test/`);
    return this.httpService.post<any>(url,body);
  }

  
  

  


}
