import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserProfile } from '../../../../../../shared/models';
import { LoginResponse } from '../../../../../../shared/models/login-response';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AdminService {
  routePath = 'admin';
  constructor(private httpService: HttpClient) {}

  get serverUrl(): string {
    return environment.apiServer;
  }

  get apiUrl(): string {
    return `${this.serverUrl}/api`;
  }

  getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}/${endpoint}`;
  }

  updateUser(body: object): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/updateUser/`);
    return this.httpService.post<UserProfile>(url,{body});
  }

  removeUser(body: object): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/removeUser/`);
    return this.httpService.post<UserProfile>(url,{body});
  }

  deactivateGroupedUsers(body: object): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/deactivateGroupedUsers/`);
    return this.httpService.post<UserProfile>(url,{body});
  }

  removeGroupedUsers(body: object): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/removeGroupedUsers/`);
    return this.httpService.post<UserProfile>(url,{body});
  }

  getUsers(body: object): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getUsers/`);
    return this.httpService.post<UserProfile>(url,{body});
  }

  getAdminUsers(body: object): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminUsers/`);
    return this.httpService.post<UserProfile>(url,{body});
  }

  getAdminSettings(): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminSettings/`);
    return this.httpService.post<UserProfile>(url,{});
  }

  getAdminTransactions(body): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getAdminTransactions/`);
    return this.httpService.post<UserProfile>(url,body);
  }

  saveChanges(body): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/saveChanges/`);
    return this.httpService.post<UserProfile>(url,body);
  }

  getDashboardMeta(body): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getDashboardMeta/`);
    return this.httpService.post<UserProfile>(url,body);
  }

  getStatuses(body): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getStatuses/`);
    return this.httpService.post<UserProfile>(url,body);
  }

  getStatusesMeta(body): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getStatusesMeta/`);
    return this.httpService.post<UserProfile>(url,body);
  }

  getValidationStates(body): Observable<UserProfile> {
    const url = this.getApiEndpoint(`${this.routePath}/getValidationStates/`);
    return this.httpService.post<UserProfile>(url,body);
  }
}
