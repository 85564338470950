import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CityService} from '@services';
import { take } from 'rxjs/operators';
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"

@Component({
  selector: 'app-landing-cities',
  templateUrl: './landing-cities.component.html',
  styleUrls: ['./landing-cities.component.scss']
})
export class LandingCitiesComponent implements OnInit {
  @Input() classExt;
  @Input() class;
  @Input() supportClass;
  cities = [];
  constructor(private cityService: CityService, private router: Router) { }

  /**
   * Gets the cities from the server
   */
  getCities(){
    this.cityService.getCities({}).pipe(take(1)).subscribe((cities)=>{
      
      this.cities = cities;
      this.drawCarousel();
    })
  }

  /**
   * Navigates to tours page with the selected city
   * @param city city to be selected
   */
  navigateWithCity(city){
    if(city.numberOfTours){
      this.router.navigate(['tours'],{queryParams: {city: city._id}});
    }
  }

  /**
   * Draws the city carousel
   */
  drawCarousel(){
    setTimeout(()=>{
      if(document.querySelector(this.classExt)){
        let slider = tns({
          container: this.classExt,
          slideBy: 'page',
          gutter: 10,
          responsive: {
            576: {
              items: 1
            },
            768: {
              items: 2
            },
            992: {
              items: 3
            },
            1200: {
              items: 4
            }
          },
          autoplay: false,
          autoplayButtonOutput: false,
          controls: false
        });
      }
    },10)
  }

  /**
   * Default component initialization
   */
  ngOnInit(): void {
      this.getCities();
  }

}
