<!-- Hero-->
<div class="page-wrapper" *ngIf="tour else loadingBlock">
  <!-- Page header-->
  <section class="container px-3 px-md-5 mt-3 mt-md-5 mb-lg-3">
    <!-- Breadcrumb-->
    <nav class="mb-3 pt-md-3" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/']" i18n>Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)"
            (click)="navigateWithCity(tour.city)">{{tour.city.name}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{tour.tourName.value}}</li>
      </ol>
    </nav>
    <div class="d-sm-flex align-items-center justify-content-between mb-4 pb-sm-2">
      <h1 class="h2 me-3 mb-sm-0 d-flex align-items-center">{{tour.tourName.value}}
      </h1>
      <div class="text-nowrap">

        <button
          class="btn btn-icon sfs-like-btn btn-xs text-primary btn-light-primary rounded-circle shadow-sm zindex-5 bookmark-icon"
          type="button" (click)="toggleLike()" [class.isLiked]="isLiked"><i class="bookmark-icon"
            [class]="isLiked ? 'fi-heart-filled' : 'fi-heart'"></i></button>

        
      </div>
    </div>
  </section>
  <!-- Gallery-->
  <!-- Gallery-->
  <section class="container overflow-auto py-0 px-3 pd-md-5 mb-lg-3">
    <div class="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside">
      <div class="detailedSlider" id="detailedImageContainer">
      
        <div class="tns-item detailedCover tns-slide-cloned"
          *ngFor="let media of tour.media; let i = index" class="gallery-item primary rounded rounded-md-3">
          <div class="sfs-detailed-gallery-element" [ngStyle]="{'background-image':'url('+media.url+')'}"></div>

        </div>
      </div>
    </div>
  </section>
  <!-- Info -->
  <section class="container py-0 px-md-5 px-3 mt-3 mt-md-0 mb-lg-3">
    <div class="row">


      <!-- Left column-->
      <div class="col-md-8 col-12 mb-md-0 mb-md-4 pb-md-0 pb-2">

    

        <!-- Details view -->
        <div class="mt-3">
          <div class="row">
            <div class="col-12 d-flex align-items-center mb-3">
              <h2 class="h4 d-flex align-items-center mb-0">
                <ng-container i18n>About the tour </ng-container>
                <div class="sfs-verified-block relative d-flex align-items-center shadow" *ngIf="tour.isFactoryTour">
                  <img src="../../../assets/img/cards/verified.svg" width="25" height="25" class="me-1">
                  <ng-container i18n>Wingman made</ng-container>
                </div>
              </h2>
              <div class="ms-auto text-center d-none d-md-block">
                <span class="sfs-text-08 me-2">{{tour.user.firstName}} {{tour.user.lastName}}</span>
                <img
                   class="rounded-circle medium" alt="Peter1"
                  [src]="tour.user.profilePicture ? tour.user.profilePicture : '../../../../assets/img/general/no-user.png'">
                
              </div>
            </div>
          </div>
          <hr>
          <div class="pb-3 mt-3">
            <p class="mb-0">{{tourDescription}}</p>
            <a class="collapse-label sfs-text-08 sfs-no-link" *ngIf="tour?.tourDescription?.value.length >= 250" href="javascript:void(0)" (click)="toggleTourDescription()"
             ><span *ngIf="pageSettings.descriptionToggled else hideBlock"><ng-container i18n> Show more description</ng-container></span> </a>
             <ng-template #hideBlock>
              <span><ng-container i18n> Hide description</ng-container></span> 
             </ng-template>
          </div>
          <hr>
          <!-- Categories -->
          <div class="pb-3 mt-3">
            <h2 class="h4" i18n>Categories</h2>
            <ul class="list-unstyled row row-cols-lg-3 row-cols-md-2 row-cols-1 gy-1 mb-1 text-nowrap">
              <li class="col" *ngFor="let category of tour.categoriesMapped"><i class="mt-n1 me-2 fs-lg align-middle"
                  [class]="category.icon"></i>{{category.name}}</li>

            </ul>
          </div>
          <hr>
          <!-- Properties -->
          <div class="pb-3 mt-3">
            <h2 class="h4" i18n>Properties</h2>
            <ul class="list-unstyled row row-cols-lg-2 row-cols-md-2 row-cols-1 gy-1 mb-1 text-nowrap">
              <li class="col d-flex align-items-center" *ngIf="tour.distance"><img class="me-1" src="../../../assets/icons/duotone/Contacts/Map.svg" width="20">
                <ng-container i18n> Walking distance:</ng-container>
                {{(tour.distance/1000).toFixed(1)}} km
              </li>
              <li class="col d-flex align-items-center" *ngIf="tour.duration"><img class="me-1" src="../../../assets/icons/duotone/Contacts/Clock.svg" width="20">
                <ng-container i18n>Time:</ng-container>&nbsp;<span *ngIf="tour.durationFormatted>1 else minutesBlock">{{tour.durationFormatted}} <span i18n>hours</span></span>
                <ng-template #minutesBlock>
                  {{tour.duration}} <span i18n>mins</span>
                </ng-template>
              </li>
              <li class="col d-flex align-items-center mt-2"><img class="me-1" src="../../../assets/icons/duotone/Business/Building.svg" width="20">
                <ng-container i18n>Type:</ng-container> {{tour.isIndoor ? locales.indoor
                :
                locales.outdoor}}
              </li>
              <li class="col d-flex align-items-center mt-2" *ngIf="tour.address"><img class="me-1" src="../../../assets/icons/duotone/Contacts/Map-Marker.svg" width="20">
                {{tour.address.city}} <span
                  *ngIf="tour.address.country">, {{tour.address.country}}</span> </li>
                  <li class="col d-flex align-items-center mt-2" *ngIf="tour.city"><img class="me-1" src="../../../assets/icons/duotone/Contacts/Map-Marker.svg" width="20">
                    {{tour.city.name}} <span
                      *ngIf="tour.city.country">, {{tour.city.country}}</span> </li>

            </ul>
          </div>
          <hr>
          <!-- Categories -->
          <div class=" pb-3 mt-3">
            <h2 class="h4" i18n>Available languages</h2>
            <ul class="list-unstyled row row-cols-lg-3 row-cols-md-2 row-cols-1 gy-1 mb-1 text-nowrap">
              <li class="col" *ngFor="let language of tour.languages"><a href="javascript:void(0)" class="sfs-no-link"
                  (click)="navigateToLocalePage(language.code)"><span
                    class="flag-icon flag-icon-{{language.code === 'en' ? 'gb' : language.code}}"></span>
                  {{language.name}}</a></li>
            </ul>

          </div>
          <hr>
          <!-- Sights-->
          <div class="pb-md-3 mt-3">
            <h2 class="h4" i18n>Tour sights</h2>
            <app-minified-sight-list [totalNumberOfSights]="tour.sights.length" [sights]="tour.sights"
              (sightSelected)="sightSelected($event)"></app-minified-sight-list>
          </div>
          <hr *ngIf="reviews && reviews.length">
          <!-- Reviews -->
          <div class=" pb-md-3 mt-3"  *ngIf="reviews && reviews.length">
            <h2 class="h4" i18n>Reviews</h2>
            <app-reviews-block [type]="'minified'" [reviewObj]="reviewObj" [reviews]="reviews" [tour]="tour"></app-reviews-block>
            <div class="row" *ngIf="reviews && reviews.length>2">
              <div class="col-12 text-center">
                <button class="btn sfs-book-btn btn-primary text-truncate mt-1" (click)="showAllReviews(reviewsModal)"><ng-container>Show all reviews</ng-container> ({{reviews.length}})</button>
              </div>
            </div>
            
          </div>
          <!-- 3D Map -->
          <div class="mb-4 pb-md-3 mt-3">
            <h2 class="h4 d-flex align-items-center">
              <ng-container i18n>3D Tour map</ng-container>
            </h2>
            <app-tour-sightseeing [tour]="tour"></app-tour-sightseeing>
          </div>
          <!-- Map -->
          <div class="mb-4 pb-md-3">
            <h2 class="h4" i18n>Tour map</h2>
            <app-tour-map [sights]="tour.sights" [type]="'detailed'"></app-tour-map>
          </div>
          <!-- Similar tours -->
          <app-similar-tours [id]="tour._id" [class]="'similar'" [classExt]="'.similar'"></app-similar-tours>

        </div>
        <!-- End of Details view -->





      </div>
      <!-- Sidebar-->
      <aside class="col-md-4">

        <div class="sfs-sticky top20">
          <!-- Booking card-->
          <div class="card mb-4 p-2 shadow-sm d-none d-md-flex">
            <div class="card-body">


              <!-- Place info-->
              <div class="d-flex align-items-start mb-3 pb-2 border-bottom">
                <div>
                  <h3 class="h5 mb-2"> {{tour.tourName.value}} </h3>
                  <ul class="list-unstyled d-flex flex-wrap fs-sm">
                    <li class="me-2 mb-1 pe-1 d-flex align-items-center"><img class="me-1" src="../../../assets/icons/duotone/Interface/Star.svg" width="20" alt=""><b>{{tour.rating.toFixed(1)}}
                      </b></li>
                  
                    <li class="me-2 mb-1 pe-1 d-flex align-items-center" *ngIf="tour.duration"><img class="me-1" src="../../../assets/icons/duotone/Sport/Stopwatch.svg" width="20" alt="">
                      
                      <span *ngIf="tour.durationFormatted>1 else minutesBlock">{{tour.durationFormatted}} <span i18n>hours</span></span>
                      <ng-template #minutesBlock>
                        {{tour.duration}} <span i18n>minutes</span>
                      </ng-template>
                     
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Place pricing-->
              <div class="mb-3 pb-4 border-bottom">
                <div class="row row-cols-1">
                  <div class="col mb-3 text-left">
                    <div class="h2 mb-1 d-flex align-items-center"><img src="../../../../assets/img/icons/token.svg"
                        width="35" class="me-1">{{tour.credit}} <ng-container i18n>credits</ng-container>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <ng-container *ngTemplateOutlet="bookBlock">
                    </ng-container>
                    <div class="d-flex justify-content-center mt-2">
                      <a href="javascript:void(0)" class="sfs-text-08 sfs-no-link" (click)="buyCreditsModal()">
                        <ng-container i18n>Buy more credits</ng-container>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Place following-->
              <div class="d-flex align-items-center">
                <h4 class="h5 mb-0 me-3" i18n>Share:</h4>
                <div class="text-nowrap"><a class="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle me-2"
                    href="https://www.facebook.com/people/Wingman_guide/100088812305101/"><i class="fi-facebook"></i></a><a
                    class="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle me-2" href="https://www.instagram.com/wingman_guide/"><i
                      class="fi-instagram"></i></a><a
                    class="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle" href="https://www.tiktok.com/@wingman_guide"><i
                      class="fi-tiktok"></i></a></div>
              </div>
            </div>
          </div>
          <h2 class="h4 d-flex d-md-none" i18n>Directions</h2>
          <!-- Direction card-->
          <div class="position-relative mb-5 mb-md-0"><img class="rounded-3"
              src="../../../assets//img/detailed/map.jpeg" alt="Map">
            <div
              class="d-flex w-100 h-100 flex-column align-items-center justify-content-center position-absolute top-0 start-0">
              <a class="btn btn-primary rounded-pill stretched-link" [href]="navigationUrl" target="_blank"
                data-iframe="true" data-bs-toggle="lightbox" lg-uid="lg0"><i class="fi-route me-2"></i>
                <ng-container i18n> Get
                  directions</ng-container>
              </a>
            </div>
          </div>
        </div>


      </aside>
    </div>
  </section>
  <!-- Mobile book block -->
  <div class="sfs-mobile-book-container d-flex d-md-none">
    <ng-container *ngTemplateOutlet="bookBlock">
    </ng-container>
  </div>
  <!-- End of Mobile book block -->


</div>

<!-- Loading block -->
<ng-template #loadingBlock>
  <div class="container">
    <div class="row">
      <div class="col-12 vh-100 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <app-loader></app-loader>
          <div class="sfs-text-09" i18n>Loading tour</div>
        </div>

      </div>
    </div>
  </div>
</ng-template>
<!-- End of Loading block -->

<!-- Book block -->
<ng-template #bookBlock>
  <button class="btn sfs-book-btn btn-primary text-truncate btn-lg mt-1 w-100 mb-3 mb-md-0"
    [disabled]="!tour.isActive || !tour.isValidated" (click)="appBookModal()">
    <ng-container i18n> Buy this tour </ng-container> <img src="../../../../assets/img/icons/token.svg" width="20"
      class="ms-1">
  </button>
</ng-template>
<!-- End of Book block -->

<ng-template #reviewsModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Reviews</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <app-reviews-block  [reviewObj]="reviewObj" [reviews]="reviews" [tour]="tour"></app-reviews-block>

    </div>
  </div>
</ng-template>