import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { UserProfile } from '../../../../../../shared/models';

@Injectable({
  providedIn: "root"
})
export class UserStateService {
  public user: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  constructor() {
  }

  
}
