<!-- Footer-->
<footer class="footer pt-lg-5 pt-4 bg-dark text-light">
  <div class="container mb-4 py-4 pb-lg-5">
    <div class="row gy-4">
      <div class="col-lg-4 col-md-6 col-sm-4">
        <div class="mb-4 pb-sm-3"><a class="d-inline-block text-white" href="javascript:void(0)"><img
              src="../../../../assets/img/logo/logo_w.png" width="160"></a></div>
        <ul class="nav nav-light flex-column">
          <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap"
              href="mailto:info@wman.com"><i
                class="fi-mail mt-n1 me-1 align-middle text-primary"></i>info@wman.com</a></li>
        </ul>
      </div>
      <!-- Links-->
      <div class="col-lg-2 offset-lg-4 col-md-3 col-sm-4">
        <h3 class="fs-base text-light">Quick links</h3>
        <ul class="list-unstyled fs-sm">
          <li class="mt-2"><a class="nav-link-light" href="javascript:void(0)" [routerLink]="['/tours']" i18n>Tour search</a></li>
          <li class="mt-2"><a class="nav-link-light" *ngIf="config && config.howItWorksURL" [href]="config.howItWorksURL" target="_blank"   i18n>FAQ</a></li>
          <li class="mt-2"><a class="nav-link-light" *ngIf="config && config.termsOfServiceURL"  [href]="config.termsOfServiceURL" target="_blank"   i18n>Terms of Service</a></li>
          <li class="mt-2"><a class="nav-link-light" *ngIf="config && config.privacyPolicyURL"  [href]="config.privacyPolicyURL" target="_blank"   i18n>Privacy Policy</a></li>
          <li class="mt-2"><a class="nav-link-light" href="javascript:void(0)" (click)="utilsService.detectPlatformAndNavigateUserToStore()" i18n>Mobile App</a></li>

        </ul>
      </div>
      <!-- Links-->
      <div class="col-lg-2 col-md-3 col-sm-4">
        <h3 class="fs-base text-light">Profile</h3>
        <ul class="list-unstyled fs-sm">
          <li class="mt-2"><a class="nav-link-light" href="javascript:void(0)" [routerLink]="['/user/dashboard']"  i18n>Login</a></li>
          <li class="mt-2"><a class="nav-link-light" href="javascript:void(0)" [routerLink]="['/user/tours']"  i18n>Tours</a></li>
          <li class="mt-2"><a class="nav-link-light" href="javascript:void(0)" [routerLink]="['/user/payments']"  i18n>Payments</a></li>
          <li class="mt-2"><a class="nav-link-light" href="javascript:void(0)" [routerLink]="['/user/payments']"  i18n>Buy credits</a></li>
        </ul>
      </div>
      <!-- Subscription form-->
    <!--   <div class="col-lg-4 offset-lg-1">
        <h3 class="h4 text-light"  i18n>Subscribe to our newsletter</h3>
        <p class="fs-sm mb-4 opacity-60"  i18n>Don’t miss any relevant vacancies!</p>
        <form class="form-group form-group-light rounded-pill" style="max-width: 500px;">
          <div class="input-group input-group-sm"><span class="input-group-text text-muted"><i
                class="fi-mail"></i></span>
            <input class="form-control" type="email" placeholder="Your email" i18n-placeholder>
          </div>
          <button class="btn btn-primary btn-sm rounded-pill" type="button"  i18n>Subscribe</button>
        </form>
      </div> -->
    </div>
  </div>
  <div class="py-4 border-top border-light">
    <div class="container d-flex flex-column flex-lg-row align-items-center justify-content-between py-2">
      <!-- Copyright-->
      <p class="order-lg-1 order-2 fs-sm mb-2 mb-lg-0"><span class="text-light opacity-60">&copy; <span  i18n>All rights reserved.</span>
        </span> V1.3.9</p>
      <div class="d-flex flex-lg-row flex-column align-items-center order-lg-2 order-1 ms-lg-4 mb-lg-0 mb-4">
        <!-- Links-->
        <div class="d-flex flex-wrap fs-sm mb-lg-0 mb-4 pe-lg-4"><a class="nav-link-light px-2 mx-1"
            href="https://www.wman.com/guide/">About</a>
           <a class="nav-link-light px-2 mx-1"
            href="mailto:info@wman.com">Support</a>
            <a class="nav-link-light px-2 mx-1" href="mailto:info@wman.com" i18n>Contacts</a></div>
        <div class="d-flex align-items-center">
          <!-- Language switcher-->
          <div class="dropdown"><a
              class="nav-link nav-link-light dropdown-toggle fs-sm align-items-center p-0 fw-normal" href="#"
              id="langSwitcher" data-bs-toggle="dropdown" role="button" aria-expanded="false"> <i
              class="fi-globe mt-n1 me-1 align-middle"></i> {{localeService.activeLocaleObject.name}}</a>
            <ul class="dropdown-menu dropdown-menu-dark my-1" aria-labelledby="langSwitcher">
              <li><a class="dropdown-item text-nowrap py-1" href="/hu-HU/"><span class="flag-icon flag-icon-hu me-1"></span> <ng-container i18n>Hungarian</ng-container></a></li>
              <li><a class="dropdown-item text-nowrap py-1" href="/en-US/"><span class="flag-icon flag-icon-gb me-1"></span> <ng-container i18n>English</ng-container></a></li>
            </ul>
          </div>
          <!-- Socials-->
          <div class="ms-4 ps-lg-2 text-nowrap"><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2"
              href="https://www.facebook.com/people/Wingman_guide/100088812305101/" target="_blank"><i class="fi-facebook"></i></a>
              <a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="https://www.instagram.com/wingman_guide/" target="_blank"><i
                class="fi-instagram"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2"
              href="https://www.tiktok.com/@wingman_guide"><i class="fi-tiktok"></i></a>
            </div>
        </div>
      </div>
    </div>
  </div>
</footer>