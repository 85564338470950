import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class NotificationService {

  constructor(private toastService: ToastrService) {}

  dropSuccessNoty(title, text){
    this.toastService.success(
        title,
        text
      );
  }
}
