import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingCategoriesComponent } from './landing-categories.component';


@NgModule({
  declarations: [
    LandingCategoriesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [LandingCategoriesComponent]
})
export class LandingCategoriesModule { }
