import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourPreviewComponent } from './tour-preview.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NoDataBlockModule } from '../no-data-block/no-data-block.module';
import { TourMapModule } from '../tour-map/tour-map.module';
import {NgxDnDModule} from '@swimlane/ngx-dnd';

@NgModule({
  declarations: [
    TourPreviewComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    NoDataBlockModule,
    TourMapModule,
    NgxDnDModule
  ], 
  exports: [TourPreviewComponent]
})
export class TourPreviewModule { }
